import { UnsoldLot } from '@loom-api-types'

export const cleanToGreasy = (cleanPrice: number, coreTestYield: number) =>
  Math.round((cleanPrice ?? 0) * ((coreTestYield ?? 0) / 100))

export const greasyToClean = (greasyPrice: number, coreTestYield: number) =>
  Math.round((greasyPrice ?? 0) / ((coreTestYield ?? 0) / 100))

export const isTestedLot = (lot: UnsoldLot) => lot.coreTestYield > 1

export const calculateValueAdded = (
  offerPrice: number | string | null,
  marketPrice: number | string | null,
  kilos: number
) => {
  if (typeof offerPrice !== 'number' || typeof marketPrice !== 'number') return null
  const totalDifferenceCents = ((offerPrice - marketPrice) * kilos) / 100
  // Round to 2dp
  return Math.round(totalDifferenceCents * 100) / 100
}
