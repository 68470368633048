import { useNavigate, useParams } from 'react-router-dom'
import { Group, Select, Skeleton, Text, Title } from '@mantine/core'
import { PillAlert } from '@/components/shared/pill-alert'

type StationHeaderProps = {
  brand: string
  recordNumber: string | undefined
  onHold: boolean
  isLoading: boolean
  stationsInGroup: { brand: string; recordNumber: string }[]
}

export function StationHeader({
  brand,
  recordNumber,
  onHold,
  isLoading = false,
  stationsInGroup = [],
}: StationHeaderProps) {
  const navigate = useNavigate()
  const { stationTabValue, woolDataTabValue } = useParams()

  return (
    <Group justify='space-between'>
      {isLoading ? (
        <Skeleton visible height={34} width='60%' />
      ) : (
        <Title order={1}>
          {brand}{' '}
          <Text component='span' c='gray.5' inherit>
            {recordNumber}
          </Text>
          {onHold && <PillAlert ml='md' text='On Hold' hideIcon />}
        </Title>
      )}
      {stationsInGroup.length > 0 && (
        <Select
          data={stationsInGroup.map((station) => ({
            value: station.recordNumber,
            label: station.brand,
          }))}
          placeholder='Switch to station in group'
          value={recordNumber}
          onChange={(value) => {
            // This may get ugly as the params grow. chech this link for a soultion https://stackoverflow.com/q/73321414
            navigate(`/stations/${value}/${stationTabValue}/${woolDataTabValue || ''}`)
          }}
        />
      )}
    </Group>
  )
}
