import { useMemo } from 'react'
import { MantineReactTable, MRT_ColumnDef, useMantineReactTable } from 'mantine-react-table'
import { NumberFormatter, useMantineTheme } from '@mantine/core'
import {
  hubTableDefaultAlertBannerProps,
  hubTableDefaultProps,
} from '@/components/shared/table/table'
import { NoDataMessage } from '@/components/shared/table/table-no-data-message'
import { formatDate } from '@shared/helpers/format-date/format-date'
import { CreditorHistoryDetail } from '@loom-api-types'

interface CreditorHistoryTableProps {
  data: CreditorHistoryDetail[]
  sumOfGrossProceedsExGST?: number
  sumOfNetProceedsGSTInclusive?: number
  sumOfTotalBales?: number
  sumOfTotalNetKg?: number
  noDataMessage?: string
  isLoading?: boolean
  isError?: boolean
}

export const CreditorHistoryTable = ({
  data = [],
  sumOfGrossProceedsExGST,
  sumOfNetProceedsGSTInclusive,
  sumOfTotalBales,
  sumOfTotalNetKg,
  noDataMessage = 'No creditor history available for this station',
  isLoading,
  isError,
}: CreditorHistoryTableProps) => {
  const theme = useMantineTheme()

  const columns = useMemo<MRT_ColumnDef<CreditorHistoryDetail>[]>(
    () => [
      {
        accessorFn: (row) => `${row.stationRecordNumber} ${row.stationBrand}`,
        id: 'porg',
        header: 'P Org',
        Footer: () => <>Totals</>,
      },
      {
        id: 'paymentDate',
        header: 'Payment Date',
        accessorFn: (row) => (row.paymentDate == null ? null : new Date(row.paymentDate)),
        Cell: ({ cell }) => formatDate(cell.getValue<Date>(), 'DD/MM/YYYY'),
      },
      {
        accessorKey: 'totalBalesInPayment',
        header: 'Bales',
        mantineTableHeadCellProps: {
          align: 'right',
        },
        mantineTableBodyCellProps: {
          align: 'right',
        },
        mantineTableFooterCellProps: {
          align: 'right',
        },
        Footer: () => (
          <NumberFormatter value={sumOfTotalBales} thousandSeparator decimalScale={2} />
        ),
      },
      {
        accessorKey: 'totalNetKgInPayment',
        header: 'Net Kg',
        Cell: ({ cell }) => <NumberFormatter value={cell.getValue<number>()} thousandSeparator />,
        mantineTableHeadCellProps: {
          align: 'right',
        },
        mantineTableBodyCellProps: {
          align: 'right',
        },
        mantineTableFooterCellProps: {
          align: 'right',
        },
        Footer: () => (
          <NumberFormatter value={sumOfTotalNetKg} thousandSeparator decimalScale={2} />
        ),
      },
      {
        accessorKey: 'saleAndSeason',
        header: 'Sale',
      },
      {
        accessorKey: 'netProceedsGSTInclusive',
        header: 'Net Proceeds',
        Cell: ({ cell }) => (
          <NumberFormatter
            prefix='$'
            value={cell.getValue<number>()}
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
          />
        ),
        mantineTableHeadCellProps: {
          align: 'right',
        },
        mantineTableBodyCellProps: {
          align: 'right',
        },
        mantineTableFooterCellProps: {
          align: 'right',
        },
        Footer: () => (
          <NumberFormatter
            prefix='$'
            value={sumOfNetProceedsGSTInclusive}
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
          />
        ),
      },
      {
        accessorKey: 'grossProceedsExGST',
        header: 'Gross Proceeds',
        Cell: ({ cell }) => (
          <NumberFormatter
            prefix='$'
            value={cell.getValue<number>()}
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
          />
        ),
        mantineTableHeadCellProps: {
          align: 'right',
        },
        mantineTableBodyCellProps: {
          align: 'right',
        },
        mantineTableFooterCellProps: {
          align: 'right',
        },
        Footer: () => (
          <NumberFormatter
            prefix='$'
            value={sumOfGrossProceedsExGST}
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
          />
        ),
      },
      {
        id: 'accountSalesDate',
        header: 'Acc Sales Date',
        accessorFn: (row) => (row.accountSalesDate == null ? null : new Date(row.accountSalesDate)),
        Cell: ({ cell }) => formatDate(cell.getValue<Date>(), 'DD/MM/YYYY'),
      },
    ],
    [sumOfGrossProceedsExGST, sumOfNetProceedsGSTInclusive, sumOfTotalBales, sumOfTotalNetKg]
  )

  const table = useMantineReactTable({
    ...hubTableDefaultProps,
    columns,
    data,
    state: {
      isLoading,
      showAlertBanner: isError,
    },
    mantineToolbarAlertBannerProps: hubTableDefaultAlertBannerProps(isError),
    renderEmptyRowsFallback: () => <NoDataMessage noDataMessage={noDataMessage} />,
    mantineTableProps: {
      ...hubTableDefaultProps.mantineTableProps,
      'aria-label': 'Creditor History',
    },
    mantineTableBodyRowProps: ({ row }) => {
      const futureDate = (row.getValue('paymentDate') as Date) > new Date()
      return futureDate ? { bg: theme.colors.yellow[0] } : {}
    },
  })

  return <MantineReactTable table={table} />
}
