import { useEffect } from 'react'
import { InteractionStatus } from '@azure/msal-browser'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { Outlet } from 'react-router-dom'
import { LoadingOverlay } from '@mantine/core'
import { loginRequest } from '@/auth-config'

export function ProtectedRoute() {
  const isLoggedIn = useIsAuthenticated()
  const { instance, inProgress } = useMsal()

  useEffect(() => {
    if (inProgress === InteractionStatus.None && !isLoggedIn) {
      // This will force the user to log in as soon as the app loads if there is no active user
      instance.loginRedirect(loginRequest)
    }
  }, [isLoggedIn, inProgress])

  if (!isLoggedIn) {
    return <LoadingOverlay visible={!isLoggedIn} />
  }

  return <Outlet />
}
