import { gql } from 'graphql-request'

export const GetStationCRMDetailsGql = gql`
  query crmCompany($recordNumber: String!, $populateManagers: Boolean! = true) {
    crmCompany(stationRecordNumber: $recordNumber, populateManagers: $populateManagers) {
      tradingName
      physicalAddressStreetAndSuburb
      physicalAddressTown
      physicalAddressCityRegionState
      physicalAddressCountry
      physicalAddressRapidNumber
      nzmAreaManagerFirstName
      nzmAreaManagerLastName
      accountManagerNameFirstName
      accountManagerNameLastName
      crmLink
      offerNotes
    }
  }
`
