import { useContext } from 'react'
import { NumberFormatter, Stack, Table, Text, Title } from '@mantine/core'
import { transposeDataToContractDetails } from '@/components/stations/wool-data/wool-contracts-table/contract-details-helpers'
import { ContractDetailsSubtable } from '@/components/stations/wool-data/wool-contracts-table/contract-details-subtable'
import { contractedLotResultToString } from '@/constants/contracted-lot-result/contracted-lot-result'
import { formatDate } from '@shared/helpers/format-date/format-date'
import { WoolContract } from '@loom-api-types'
import { StationContext } from '@/pages/stations/station-context'
import classes from './wool-contracts-print.module.css'

interface WoolContractsPrintProps {
  data: WoolContract[]
  sumOfBalesContracted?: number
  season: string
}

export const WoolContractsPrint = ({
  data = [],
  sumOfBalesContracted = 0,
  season = '',
}: WoolContractsPrintProps) => {
  const stationContext = useContext(StationContext)

  return (
    <Stack gap='sm'>
      <Title>{stationContext?.brand} Wool Contracts</Title>
      <Text>Season: {season}</Text>
      {data.length > 0 &&
        data.map((contract, index) => (
          <div key={index}>
            <Table>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Contract Name</Table.Th>
                  <Table.Th>Contract Reference</Table.Th>
                  <Table.Th className={classes.alignRight}>Bales Contracted</Table.Th>
                  <Table.Th className={classes.alignRight}>Target Micron</Table.Th>
                  <Table.Th>Delivery Date</Table.Th>
                  <Table.Th className={classes.alignRight}>Average Price (Clean C/KG)</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                <Table.Tr className={classes.noBreakAfter}>
                  <Table.Td>{contract.contractName}</Table.Td>
                  <Table.Td>{contract.purchaseAccountRecordNumber}</Table.Td>
                  <Table.Td className={classes.alignRight}>{contract.balesContracted}</Table.Td>
                  <Table.Td className={classes.alignRight}>{contract.targetMicron}</Table.Td>
                  <Table.Td>{formatDate(contract.contractDeliveryDate, 'DD/MM/YYYY')}</Table.Td>
                  <Table.Td className={classes.alignRight}>
                    <NumberFormatter
                      prefix='$'
                      value={contract.averagePriceCLN}
                      thousandSeparator
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td className={classes.subtable}>
                    <ContractDetailsSubtable data={transposeDataToContractDetails(contract)} />
                  </Table.Td>
                  <Table.Td colSpan={5} className={classes.subtable}>
                    <Stack gap='sm'>
                      <Table>
                        <Table.Thead>
                          <Table.Tr>
                            <Table.Th>Reference</Table.Th>
                            <Table.Th className={classes.alignRight}>Bales</Table.Th>
                            <Table.Th>Line Number</Table.Th>
                            <Table.Th className={classes.alignRight}>Net KG</Table.Th>
                            <Table.Th className={classes.alignRight}>Micron</Table.Th>
                            <Table.Th>Result</Table.Th>
                            <Table.Th className={classes.alignRight}>Price Clean</Table.Th>
                            <Table.Th>Reda Type</Table.Th>
                            <Table.Th>Payment Date</Table.Th>
                          </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>
                          {contract?.contractedLots?.length === 0 && (
                            <Table.Tr>
                              <Table.Td className={classes.alignCenter} colSpan={9}>
                                No contracted lots
                              </Table.Td>
                            </Table.Tr>
                          )}
                          {contract?.contractedLots?.length > 0 &&
                            contract.contractedLots.map((lot, lotIndex) => (
                              <Table.Tr key={lotIndex}>
                                <Table.Td>{lot.reference}</Table.Td>
                                <Table.Td className={classes.alignRight}>{lot.bales}</Table.Td>
                                <Table.Td>{lot.lineNumber}</Table.Td>
                                <Table.Td className={classes.alignRight}>
                                  <NumberFormatter value={lot.netKg} thousandSeparator />
                                </Table.Td>
                                <Table.Td className={classes.alignRight}>
                                  <NumberFormatter value={lot.micron} decimalScale={2} />
                                </Table.Td>
                                <Table.Td>
                                  {contractedLotResultToString(lot.result ?? null)}
                                </Table.Td>
                                <Table.Td className={classes.alignRight}>
                                  <NumberFormatter
                                    prefix='$'
                                    value={lot.priceClean}
                                    thousandSeparator
                                    decimalScale={2}
                                    fixedDecimalScale
                                  />
                                </Table.Td>
                                <Table.Td>{lot.redaType}</Table.Td>
                                <Table.Td>{formatDate(lot.paymentDate, 'DD/MM/YYYY')}</Table.Td>
                              </Table.Tr>
                            ))}
                        </Table.Tbody>
                      </Table>
                      {contract.totalBalesAccepted !== undefined && (
                        <Text size='sm'>
                          <NumberFormatter value={contract.totalBalesAccepted} thousandSeparator />{' '}
                          total bales accepted
                        </Text>
                      )}
                    </Stack>
                  </Table.Td>
                </Table.Tr>
              </Table.Tbody>
            </Table>
          </div>
        ))}
      <Text fw='bold'>Total Bales Contracted: {sumOfBalesContracted}</Text>
    </Stack>
  )
}
