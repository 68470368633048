import { createContext, useState } from 'react'
import { UnsoldLot } from '@loom-api-types'

export type OffersContextType = {
  stationBrand: string
  stationRecordNumber: string
  offerLots: UnsoldLot[]
}

export type OfferDataType = {
  offerData: OffersContextType | null
  setOfferData: (data: OffersContextType | null) => void
}

export const OffersContext = createContext<OfferDataType>({
  offerData: null,
  setOfferData: () => {},
})

export const OffersContextProvider = ({ children }: any) => {
  const [offerData, setOfferData] = useState<OffersContextType | null>(null)
  return (
    <OffersContext.Provider value={{ offerData, setOfferData }}>{children}</OffersContext.Provider>
  )
}
