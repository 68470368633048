import { useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Group, Stack, Switch, Title } from '@mantine/core'
import { UnsoldLotsTable } from '@/components/offers/unsold-lots-table/unsold-lots-table'
import { GetUnsoldLotsGql } from '@/graphql/queries/offers/get-unsold-lots.graphql'
import { useAuthenticatedGraphQuery } from '@/hooks/use-authenticated-query'
import { OffersContext } from '@/pages/offers/offers-context'

export function UnsoldLots() {
  const [viewTypedLots, setViewTypedLots] = useState(false)
  const [viewTestedLots, setViewTestedLots] = useState(false)
  const navigate = useNavigate()
  const { offerData } = useContext(OffersContext)

  // Get the unsold lots data
  const { data, isLoading, isError } = useAuthenticatedGraphQuery({
    queryKey: ['unsoldLotsData', viewTypedLots, viewTestedLots],
    gql: GetUnsoldLotsGql,
    queryParams: {
      testedLots: viewTestedLots,
      typedLots: viewTypedLots,
    },
  })

  const startOffer = () => {
    // navigate to /offers/new
    navigate('/offers/new')
  }

  const noSelectedLots = useMemo(() => (offerData?.offerLots ?? []).length === 0, [offerData])

  return (
    <Stack>
      <Group>
        <Title order={2} data-testid='offers-unsold-lots' mr='auto'>
          Unsold Lots
        </Title>
        <Switch
          label='Typed lots'
          checked={viewTypedLots}
          onChange={(event) => setViewTypedLots(event.currentTarget.checked)}
        />
        <Switch
          label='Tested lots'
          checked={viewTestedLots}
          onChange={(event) => setViewTestedLots(event.currentTarget.checked)}
        />
        <Button onClick={startOffer} disabled={noSelectedLots}>
          Make offer on selected
        </Button>
      </Group>
      <UnsoldLotsTable
        data={data?.listUnsoldLots?.results ?? []}
        isLoading={isLoading}
        isError={isError}
      />
    </Stack>
  )
}
