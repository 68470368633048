import { gql } from 'graphql-request'

export const CreateOfferGql = gql`
  mutation createOffer(
    $woolLotOfferModels: [WoolLotOfferModelInput!]!
    $offerContactSelections: [OfferContactSelectionInput!]!
    $expiryDateTime: DateTime!
    $paymentDate: LocalDate!
    $personalisedComment: String
    $from: String!
  ) {
    createOffer(
      woolLotOfferModels: $woolLotOfferModels
      offerContactSelections: $offerContactSelections
      expiryDateTime: $expiryDateTime
      paymentDate: $paymentDate
      personalisedComment: $personalisedComment
      from: $from
    ) {
      offerId
      offerValidationFailureReason
      offerCreationSuccess
      woolLotValidationFailures {
        existingWoolLotOfferId
        existingWoolLotOfferStatus
        woolLotId
      }
    }
  }
`
