import { IconInfoCircle } from '@tabler/icons-react'
import { Alert } from '@mantine/core'

export enum AlertLevel {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
}

type IconAlertProps = {
  title: string
  level?: AlertLevel
  message?: string
}

const levelToColour = {
  info: 'blue',
  warning: 'yellow',
  error: 'red',
  success: 'green',
}

export function IconAlert({ level = AlertLevel.INFO, title, message = '' }: IconAlertProps) {
  const icon = <IconInfoCircle />
  const colour = levelToColour[level]

  return (
    <Alert title={title} icon={icon} color={colour}>
      {message}
    </Alert>
  )
}
