import { gql } from 'graphql-request'

export const ListOffersGql = gql`
  query listOffers(
    $currentPage: Int!
    $pageSize: Int!
    $orderBy: ListOffersOrderByOptions!
    $orderByAscending: Boolean!
    $stationRecordNumbers: [String!]
    $offerStatuses: [OfferStatus!]
  ) {
    listOffers(
      request: {
        currentPage: $currentPage
        pageSize: $pageSize
        orderByAscending: $orderByAscending
        orderBy: $orderBy
        stationRecordNumbers: $stationRecordNumbers
        offerStatuses: $offerStatuses
      }
    ) {
      currentPage
      totalPageCount
      totalResultCount
      results {
        id
        stationBrand
        stationRecordNumber
        status
        created
        createdBy
        offerFrom
        completedDateTime
        paymentDate
        expiryDateTime
        personalisedComment
        lines {
          status
          internalReferenceCode
          lineNumber
          mainBaleHeaderPurchaseDescription
          totalBales
          coreTestMicron
          coreTestVegetableMatterBase
          coreTestYield
          coreTestColourYZ
          coreTestStapleLengthGreasy
          coreTestStapleStrength
          cleanOfferPrice
          greasyOfferPrice
          cleanMarketPrice
          valueAdd
          brandPartner
        }
      }
    }
  }
`
