import { useMemo } from 'react'
import { MantineReactTable, MRT_ColumnDef, useMantineReactTable } from 'mantine-react-table'
import { NumberFormatter, Stack, Text } from '@mantine/core'
import {
  hubTableDefaultAlertBannerProps,
  hubTableDefaultProps,
} from '@/components/shared/table/table'
import { NoDataMessage } from '@/components/shared/table/table-no-data-message'
import { contractedLotResultToString } from '@/constants/contracted-lot-result/contracted-lot-result'
import { formatDate } from '@shared/helpers/format-date/format-date'
import { ContractedLot, ContractedLotResult } from '@loom-api-types'

type ContractedLotsSubtableProps = {
  data: ContractedLot[]
  totalBalesAccepted?: number | undefined
  noDataMessage?: string
  isLoading?: boolean
  isError?: boolean
}

export const ContractedLotsSubtable = ({
  data = [],
  totalBalesAccepted = undefined,
  noDataMessage = 'No values to display',
  isLoading,
  isError,
}: ContractedLotsSubtableProps) => {
  const columns = useMemo<MRT_ColumnDef<ContractedLot>[]>(
    () => [
      {
        accessorKey: 'reference',
        header: 'Reference',
      },
      {
        accessorKey: 'bales',
        header: 'Bales',
        mantineTableHeadCellProps: {
          align: 'right',
        },
        mantineTableBodyCellProps: {
          align: 'right',
        },
      },
      {
        accessorKey: 'lineNumber',
        header: 'Line Number',
      },
      {
        accessorKey: 'netKg',
        header: 'Net KG',
        Cell: ({ cell }) => <NumberFormatter value={cell.getValue<number>()} thousandSeparator />,
        mantineTableHeadCellProps: {
          align: 'right',
        },
        mantineTableBodyCellProps: {
          align: 'right',
        },
      },
      {
        accessorKey: 'micron',
        header: 'Micron',
        Cell: ({ cell }) => <NumberFormatter value={cell.getValue<number>()} decimalScale={2} />,
        mantineTableHeadCellProps: {
          align: 'right',
        },
        mantineTableBodyCellProps: {
          align: 'right',
        },
      },
      {
        accessorKey: 'result',
        header: 'Result',
        Cell: ({ cell }) => contractedLotResultToString(cell.getValue<ContractedLotResult>()),
      },
      {
        accessorKey: 'priceClean',
        header: 'Price Clean',
        Cell: ({ cell }) => (
          <NumberFormatter
            prefix='$'
            value={cell.getValue<number>()}
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
          />
        ),
        mantineTableHeadCellProps: {
          align: 'right',
        },
        mantineTableBodyCellProps: {
          align: 'right',
        },
      },
      {
        accessorKey: 'redaType',
        header: 'Reda Type',
      },
      {
        accessorKey: 'paymentDate',
        header: 'Payment Date',
        Cell: ({ cell }) => formatDate(cell.getValue<Date>(), 'DD/MM/YYYY'),
      },
    ],
    []
  )

  const table = useMantineReactTable({
    ...hubTableDefaultProps,
    columns,
    data,
    state: {
      isLoading,
      showAlertBanner: isError,
    },
    mantineToolbarAlertBannerProps: hubTableDefaultAlertBannerProps(isError),
    renderEmptyRowsFallback: () => <NoDataMessage noDataMessage={noDataMessage} />,
    mantineTableProps: {
      ...hubTableDefaultProps.mantineTableProps,
      'aria-label': 'Contracted lots',
    },
    enablePagination: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
  })

  return (
    <Stack>
      <MantineReactTable table={table} />
      {totalBalesAccepted !== undefined && (
        <Text size='sm'>
          <NumberFormatter value={totalBalesAccepted} thousandSeparator /> total bales accepted
        </Text>
      )}
    </Stack>
  )
}
