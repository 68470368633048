import { NavLink } from 'react-router-dom'
import { Button, Collapse, Container, Group, Stack, Text, Title } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import classes from '@/components/shared/error-page-content/error-page-content.module.css'

type ErrorPageProps = {
  title: string
  errorCode?: string
  message?: string
}

const GRAPHQL_ERROR_HEADER = 'GraphQL Error'

export function ErrorPageContent({ title, errorCode, message }: ErrorPageProps) {
  const [opened, { toggle }] = useDisclosure(false)

  const isGraphQLError = (errorMessage: string) => errorMessage.includes(GRAPHQL_ERROR_HEADER)

  return (
    <Container className={classes.root}>
      <Stack gap='xl'>
        <Stack gap='xs'>
          {errorCode && <div className={classes.errorCode}>{errorCode}</div>}
          <Title className={classes.title}>{title}</Title>
        </Stack>
        {message && isGraphQLError(message) ? (
          <>
            <Text size='lg' ta='center' className={classes.description}>
              An error was returned from the API
            </Text>
            <Group justify='center' mb={5}>
              <Button onClick={toggle} variant='outline'>
                Show error details
              </Button>
            </Group>
            <Collapse in={opened}>
              <Text size='lg' ta='center' className={classes.description}>
                {message}
              </Text>
            </Collapse>
            <Text size='lg' ta='center' className={classes.description}>
              Please use the button below to continue.
            </Text>
          </>
        ) : (
          <Text size='lg' ta='center' className={classes.description}>
            {message}
          </Text>
        )}
        <Group justify='center'>
          {/* Reload document is needed in case of all graphQL errors (including 404), menu nav won't work until document is reloaded */}
          <Button to='/' size='md' component={NavLink} reloadDocument>
            Take me to the home page
          </Button>
        </Group>
      </Stack>
    </Container>
  )
}
