import { useState } from 'react'
import { ColumnFiltersState, PaginationState } from '@tanstack/react-table'
import { Stack, Title } from '@mantine/core'
import { OffersTable } from '@/components/offers/offers-table/offers-table'
import { DEFAULT_PAGINATION_SIZE } from '@/components/shared/table/table'
import { GetOfferStationsGql } from '@/graphql/queries/offers/get-offer-stations.graphql'
import { ListOffersGql } from '@/graphql/queries/offers/list-offers.graphql'
import { useAuthenticatedGraphQuery } from '@/hooks/use-authenticated-query'
import { ListOffersOrderByOptions } from '@loom-api-types'

export function ViewOffers() {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: DEFAULT_PAGINATION_SIZE,
  })
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

  const { data, isError, isFetching, isLoading } = useAuthenticatedGraphQuery({
    queryKey: ['listOffers', pagination, columnFilters],
    gql: ListOffersGql,
    queryParams: {
      currentPage: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
      orderBy: ListOffersOrderByOptions.Created,
      orderByAscending: false,
      stationRecordNumbers: columnFilters.find((filter) => filter.id === 'stationName')
        ?.value as string,
      offerStatuses: columnFilters.find((filter) => filter.id === 'status')?.value as string,
    },
  })

  const {
    data: offerStationsData,
    isError: isOfferStationsError,
    isFetching: isOfferStationsFetching,
    isLoading: isOfferStationsLoading,
  } = useAuthenticatedGraphQuery({
    queryKey: ['getOfferStations'],
    gql: GetOfferStationsGql,
  })

  return (
    <Stack>
      <Title order={2} data-testid='offers-view-offers'>
        View Offers
      </Title>
      <OffersTable
        data={data?.listOffers}
        offerStationsData={offerStationsData?.getOfferStations}
        isError={isError || isOfferStationsError}
        isFetching={isFetching || isOfferStationsFetching}
        isLoading={isLoading || isOfferStationsLoading}
        pagination={pagination}
        setPagination={setPagination}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
      />
    </Stack>
  )
}
