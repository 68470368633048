import { MantineFontSize, Skeleton, Text } from '@mantine/core'

type ValueProps = {
  value: string | undefined | null
  unit?: string
  emphasiseValue?: boolean
  valueWarning?: boolean
  isLoading?: boolean
  size?: MantineFontSize
  isUnitTrailing?: boolean
}

/**
 * Renders a value with optional emphasis, warning color, unit, and loading state.
 *
 * @param {Object} props - The properties object.
 * @param {string | number | null} props.value - The value to display.
 * @param {boolean} [props.emphasiseValue] - Whether to emphasize the value with bold font weight.
 * @param {boolean} [props.valueWarning] - Whether to display the value in a warning color.
 * @param {string} [props.unit] - The unit to display alongside the value.
 * @param {boolean} [props.isLoading=false] - Whether the value is currently loading.
 * @param {string} [props.size='xxl'] - The size of the text displaying the value.
 * @param {boolean} [props.isUnitLeading=true] - Whether the unit should be displayed after the value. When false, the unit is displayed before the value.
 *
 * @returns {JSX.Element | null} The rendered value component, or null if the value is null.
 */
export function Value({
  value,
  emphasiseValue,
  valueWarning,
  unit,
  isLoading = false,
  size = 'xxl',
  isUnitTrailing = true,
}: ValueProps) {
  if (isLoading) {
    return <Skeleton height={34} />
  }
  if (value == null) {
    return null
  }

  const unitElement = (
    <Text
      component='span'
      size='xxs'
      fw={500}
      c='gray.5'
      {...(isUnitTrailing ? { ml: 'xxs' } : { mr: 'xxs' })}
    >
      {unit}
    </Text>
  )

  return (
    <Text size={size} fw={emphasiseValue ? 700 : 400} c={valueWarning ? 'red.9' : ''}>
      {!isUnitTrailing && unitElement}
      {value}
      {isUnitTrailing && unitElement}
    </Text>
  )
}
