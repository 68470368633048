import { GetStationCRMDetailsGql } from '@/graphql/queries/get-station-crm-details.graphql'
import { useAuthenticatedGraphQuery } from '@/hooks/use-authenticated-query'

export function useStationCRMDetails(
  stationRecordNumber?: string,
  populateManagers: boolean = true
) {
  return useAuthenticatedGraphQuery({
    queryKey: ['stationCRMDetails', stationRecordNumber, populateManagers],
    gql: GetStationCRMDetailsGql,
    queryParams: { recordNumber: stationRecordNumber, populateManagers },
    retry: false,
  })
}
