import { ErrorBoundary } from 'react-error-boundary'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { ProtectedRoute } from '@/components/protected-route'
import { AppShell } from '@/components/shared/app-shell/app-shell'
import { ErrorPage } from '@/pages/error.page'
import { HomePage } from '@/pages/home.page'
import { CreateOfferPage } from '@/pages/offers/create-offer/create-offer.page'
import OffersLayout from '@/pages/offers/offers-layout'
import { OffersPage } from '@/pages/offers/offers.page'
import { PageNotFound } from '@/pages/page-not-found.page'
import { StationSearchPage } from '@/pages/stations/search/station-search.page'
import { StationPage } from '@/pages/stations/station.page'
import { TypingProgram } from '@/pages/typing/program/typing-program.page'
import { TypedLotsPage } from '@/pages/typing/typed-lots/typed-lots.page'
import { WoolStoreToolsPage } from './pages/wool-store/wool-store-tools.page'

export const routerConfig = [
  {
    element: (
      <ErrorBoundary fallbackRender={ErrorPage}>
        <ProtectedRoute />
      </ErrorBoundary>
    ),
    children: [
      {
        path: '/',
        element: <AppShell />,
        children: [
          {
            path: '/',
            element: <HomePage />,
          },
          {
            path: '/stations/',
            element: <StationSearchPage />,
          },
          {
            path: '/stations/:stationRecordNumber',
            element: <StationPage />,
          },
          {
            path: '/stations/:stationRecordNumber/:stationTabValue',
            element: <StationPage />,
          },
          {
            path: '/stations/:stationRecordNumber/:stationTabValue/:woolDataTabValue',
            element: <StationPage />,
          },

          {
            path: '/offers/',
            element: <OffersLayout />,
            children: [
              {
                path: '',
                element: <OffersPage />,
              },
              {
                path: ':offersTabValue',
                element: <OffersPage />,
              },
              {
                path: 'new',
                element: <CreateOfferPage />,
              },
            ],
          },
          {
            path: '/typing/program',
            element: <TypingProgram />,
          },
          {
            path: '/typing/typed-lots',
            element: <TypedLotsPage />,
          },
          {
            path: '/wool-store-tools',
            element: <WoolStoreToolsPage />,
          },
          {
            path: '*',
            element: <PageNotFound />,
          },
        ],
      },
    ],
  },
]

const router = createBrowserRouter(routerConfig, {
  future: {
    v7_relativeSplatPath: true,
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    v7_skipActionErrorRevalidation: true,
  },
})

export function Router() {
  return (
    <RouterProvider
      router={router}
      future={{
        v7_startTransition: true,
      }}
    />
  )
}
