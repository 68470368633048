import { IconCheck, IconX } from '@tabler/icons-react'
import { rem } from '@mantine/core'
import { notifications } from '@mantine/notifications'

export const showErrorNotification = (message: string, options = { autoClose: false }) => {
  notifications.show({
    message,
    icon: <IconX style={{ width: rem(18), height: rem(18) }} />,
    color: 'red',
    autoClose: options.autoClose,
    position: 'top-right',
  })
}

export const showSuccessNotification = (message: string, options = { autoClose: true }) => {
  notifications.show({
    message,
    icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
    color: 'green',
    autoClose: options.autoClose,
    position: 'top-right',
  })
}
