import { useMemo } from 'react'
import { IconFileTextSpark } from '@tabler/icons-react'
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table'
import {
  Fieldset,
  Grid,
  Group,
  Input,
  NumberFormatter,
  NumberInput,
  Paper,
  SimpleGrid,
  Stack,
  Switch,
  Text,
  TextInput,
  Title,
} from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'
import { useDebouncedCallback } from '@mantine/hooks'
import { unsoldLotsColumns } from '@/components/offers/unsold-lots-columns'
import { hubTableDefaultProps } from '@/components/shared/table/table'
import {
  calculateValueAdded,
  cleanToGreasy,
  greasyToClean,
  isTestedLot,
} from '@/helpers/offer-prices/offer-prices'
import { UnsoldLot } from '@loom-api-types'
import { OfferEditorData } from '@/pages/offers/create-offer/create-offer.page'
import { OFFER_FORM_DEBOUNCE_TIME } from '@/pages/offers/offer-constants'

type OfferLotCardProps = {
  lot: UnsoldLot
  form?: UseFormReturnType<OfferEditorData, (values: OfferEditorData) => OfferEditorData> | null
  index?: number
}

export function OfferLotCard({ lot, form = null, index = 0 }: OfferLotCardProps) {
  // Show all the same columns as the unsold lots table, but without the P Org and Status columns
  // Memoize the filtered columns to prevent recreating on every render
  const offerLotsColumns = useMemo(
    () =>
      unsoldLotsColumns.filter((column) => column.header !== 'P Org' && column.header !== 'Status'),
    []
  )

  const table = useMantineReactTable({
    ...hubTableDefaultProps,
    columns: offerLotsColumns,
    data: [lot],
    initialState: {
      ...hubTableDefaultProps.initialState,
      showColumnFilters: false,
    },
    mantineTableProps: {
      ...hubTableDefaultProps.mantineTableProps,
      'aria-label': 'Unsold Lots',
    },
    enableFilters: false,
    enablePagination: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    getRowId: (row: UnsoldLot) => row.internalReferenceCode ?? undefined,
  })

  // Memoize form keys to prevent object recreation
  const formKeys = useMemo(
    () => ({
      cleanOfferPrice: `offerLotsInfo.${index}.cleanOfferPrice`,
      cleanMarketPrice: `offerLotsInfo.${index}.cleanMarketPrice`,
      greasyOfferPrice: `offerLotsInfo.${index}.greasyOfferPrice`,
      greasyMarketPrice: `offerLotsInfo.${index}.greasyMarketPrice`,
      valueAdd: `offerLotsInfo.${index}.valueAdd`,
      displayValueAdd: `offerLotsInfo.${index}.displayValueAdd`,
      brandPartner: `offerLotsInfo.${index}.brandPartner`,
    }),
    [index]
  )

  const priceUnit = (
    <Text size='xs' mr='md'>
      c/Kg
    </Text>
  )

  // Get the default onChange functions and props for the clean and greasy offer prices
  const { onChange: defaultCleanOfferOnChange, ...cleanOfferProps } =
    form?.getInputProps(formKeys.cleanOfferPrice) ?? {}

  const { onChange: defaultGreasyOfferOnChange, ...greasyOfferProps } =
    form?.getInputProps(formKeys.greasyOfferPrice) ?? {}

  // Convert the clean price to a greasy price and set it in the form
  const setGreasyOfferPrice = useDebouncedCallback((cleanPrice: number | string) => {
    const updatePrice =
      cleanPrice === '' ? cleanPrice : cleanToGreasy(Number(cleanPrice), lot?.coreTestYield)
    form?.setFieldValue(formKeys.greasyOfferPrice, updatePrice)
  }, OFFER_FORM_DEBOUNCE_TIME)

  // Convert the greasy price to a clean price and set it in the form (if the lot is tested)
  const setCleanOfferPrice = useDebouncedCallback((greasyPrice: number | string) => {
    // Don't update the clean price for untested lots
    if (!isTestedLot(lot)) {
      return
    }

    const updatePrice =
      greasyPrice === '' ? greasyPrice : greasyToClean(Number(greasyPrice), lot?.coreTestYield)
    form?.setFieldValue(formKeys.cleanOfferPrice, updatePrice)
  }, OFFER_FORM_DEBOUNCE_TIME)

  // Validate the clean prices for this lot only
  // form.validate will validate lots the user hasn't touched yet
  const validateCleanPrices = () => {
    form?.validateField(formKeys.cleanOfferPrice)
    form?.validateField(formKeys.cleanMarketPrice)
  }

  // Validate the greasy prices for this lot only
  // form.validate will validate lots the user hasn't touched yet
  const validateGreasyPrices = () => {
    form?.validateField(formKeys.greasyOfferPrice)
    form?.validateField(formKeys.greasyMarketPrice)
  }

  const validatePrices = useDebouncedCallback(() => {
    validateCleanPrices()
    validateGreasyPrices()

    // Set value add
    const lotValues = form?.getValues().offerLotsInfo[index]
    if (lotValues) {
      let offerPrice
      let marketPrice
      let kilos
      if (lotValues.isTested) {
        offerPrice = lotValues.cleanOfferPrice
        marketPrice = lotValues.cleanMarketPrice
        kilos = lot.netKg * (lot.coreTestYield / 100)
      } else {
        offerPrice = lotValues.greasyOfferPrice
        marketPrice = lotValues.greasyMarketPrice
        kilos = lot.netKg
      }
      const valueAdd = calculateValueAdded(offerPrice, marketPrice, kilos)
      form?.setFieldValue(formKeys.valueAdd, valueAdd)
    }
  }, OFFER_FORM_DEBOUNCE_TIME)

  return (
    <Paper p='md' withBorder bd='1px solid gray.2' bg='gray.0'>
      <Stack>
        <Group>
          <IconFileTextSpark color='var(--mantine-primary-color-filled)' />
          <Title order={2} size='h4'>
            Offer Line {index + 1}
          </Title>
        </Group>
        <MantineReactTable table={table} />

        <Grid>
          <Grid.Col span={4}>
            <Fieldset
              {...(isTestedLot(lot)
                ? {
                    bd: '1px solid green.2',
                    bg: 'green.0',
                    onChange: () => validatePrices(),
                  }
                : {
                    variant: 'filled',
                    disabled: true,
                  })}
            >
              <SimpleGrid cols={2}>
                <NumberInput
                  aria-label={`Lot ${index + 1}: clean offer price`}
                  allowDecimal={false}
                  allowNegative={false}
                  hideControls
                  clampBehavior='strict'
                  min={0}
                  width='sm'
                  {...cleanOfferProps}
                  key={form?.key(formKeys.cleanOfferPrice)}
                  label='Clean Offer'
                  rightSection={priceUnit}
                  onChange={(value) => {
                    setGreasyOfferPrice(value)
                    defaultCleanOfferOnChange(value)
                  }}
                />

                <NumberInput
                  aria-label={`Lot ${index + 1}: clean market price`}
                  allowDecimal={false}
                  allowNegative={false}
                  hideControls
                  clampBehavior='strict'
                  min={0}
                  width='sm'
                  {...form?.getInputProps(formKeys.cleanMarketPrice)}
                  key={form?.key(formKeys.cleanMarketPrice)}
                  label='Market'
                  rightSection={priceUnit}
                />
              </SimpleGrid>
            </Fieldset>
          </Grid.Col>

          <Grid.Col span={4}>
            <Fieldset onChange={validatePrices} bd='1px solid yellow.2' bg='yellow.0'>
              <SimpleGrid cols={2}>
                <NumberInput
                  aria-label={`Lot ${index + 1}: greasy offer price`}
                  allowDecimal={false}
                  allowNegative={false}
                  hideControls
                  clampBehavior='strict'
                  min={0}
                  width='sm'
                  {...greasyOfferProps}
                  key={form?.key(formKeys.greasyOfferPrice)}
                  label='Greasy Offer'
                  rightSection={priceUnit}
                  onChange={(value) => {
                    setCleanOfferPrice(value)
                    defaultGreasyOfferOnChange(value)
                  }}
                />

                <NumberInput
                  aria-label={`Lot ${index + 1}: greasy market price`}
                  allowDecimal={false}
                  allowNegative={false}
                  hideControls
                  clampBehavior='strict'
                  min={0}
                  width='sm'
                  {...form?.getInputProps(formKeys.greasyMarketPrice)}
                  key={form?.key(formKeys.greasyMarketPrice)}
                  label='Market'
                  rightSection={priceUnit}
                />
              </SimpleGrid>
            </Fieldset>
          </Grid.Col>

          <Grid.Col span={4}>
            <Fieldset variant='filled'>
              <SimpleGrid cols={2}>
                <Stack>
                  {/* Custom switch with label to match design of other inputs */}
                  <Input.Wrapper label='Display value add'>
                    <Switch
                      defaultChecked
                      label={
                        <NumberFormatter
                          prefix='$'
                          value={form?.getValues().offerLotsInfo[index].valueAdd ?? undefined}
                          thousandSeparator
                          decimalScale={0}
                          fixedDecimalScale
                        />
                      }
                      mt='xs'
                      {...form?.getInputProps(formKeys.displayValueAdd)}
                      key={form?.key(formKeys.displayValueAdd)}
                    />
                  </Input.Wrapper>
                </Stack>

                <TextInput
                  aria-label={`Lot ${index + 1}: brand partner`}
                  width='sm'
                  {...form?.getInputProps(formKeys.brandPartner)}
                  key={form?.key(formKeys.brandPartner)}
                  label='Brand Partner'
                />
              </SimpleGrid>
            </Fieldset>
          </Grid.Col>
        </Grid>
      </Stack>
    </Paper>
  )
}
