import { MantineColor, Pill } from '@mantine/core'
import { LoomWoolLotOfferStatus } from '@loom-api-types'

export enum OfferStatusOptionsLabels {
  Accepted = 'Accepted',
  Cancelled = 'Cancelled',
  Complete = 'Complete',
  Declined = 'Declined',
  Expired = 'Expired',
  UnderOffer = 'Under Offer',
}

type OfferStatusDisplayProps = {
  loomWoolLotOfferStatus?: LoomWoolLotOfferStatus | null
}

export function OfferStatusDisplay({ loomWoolLotOfferStatus }: OfferStatusDisplayProps) {
  if (!loomWoolLotOfferStatus) {
    return <></>
  }

  let text: string = 'Unknown'
  let bgColour: MantineColor = 'red.2'

  switch (loomWoolLotOfferStatus) {
    case LoomWoolLotOfferStatus.Accepted:
      text = OfferStatusOptionsLabels.Accepted
      bgColour = 'green.2'
      break
    case LoomWoolLotOfferStatus.Cancelled:
      text = OfferStatusOptionsLabels.Cancelled
      bgColour = 'grey.2'
      break
    case LoomWoolLotOfferStatus.Complete:
      text = OfferStatusOptionsLabels.Complete
      bgColour = 'green.2'
      break
    case LoomWoolLotOfferStatus.Declined:
      text = OfferStatusOptionsLabels.Declined
      bgColour = 'red.2'
      break
    case LoomWoolLotOfferStatus.Expired:
      text = OfferStatusOptionsLabels.Expired
      bgColour = 'grey.2'
      break
    case LoomWoolLotOfferStatus.UnderOffer:
      text = OfferStatusOptionsLabels.UnderOffer
      bgColour = 'orange.2'
      break
  }

  return (
    <Pill bg={bgColour} fw={600} aria-label={`Status ${text}`}>
      {text}
    </Pill>
  )
}
