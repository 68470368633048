import { useEffect, useRef } from 'react'
import { IconChevronLeft, IconMenu2 } from '@tabler/icons-react'
import { useContainerQuery } from 'react-container-query'
import { ErrorBoundary } from 'react-error-boundary'
import { Outlet } from 'react-router-dom'
import { useScrollLock } from 'usehooks-ts'
import { AppShell as MantineAppShell, Stack, useMantineTheme } from '@mantine/core'
import { useDisclosure, useFocusTrap, useMediaQuery } from '@mantine/hooks'
import { containerQueries } from '@/components/shared/app-shell/container-queries'
import { Logo, LogoProps } from '@/components/shared/app-shell/logo/logo'
import { MainMenu } from '@/components/shared/app-shell/main-menu/main-menu'
import { MenuError } from '@/components/shared/app-shell/menu-error/menu-error'
import { MobileHeader } from '@/components/shared/app-shell/mobile-header/mobile-header'
import { UserMenu } from '@/components/shared/app-shell/user-menu/user-menu'
import { Search } from '@/components/shared/search/search'
import { MAIN_MENU_LINKS } from '@/constants/menus'
import { useStationSearch } from '@/hooks/use-station-search'
import { ErrorPage } from '@/pages/error.page'
import { SEARCH_LIMIT } from '@/pages/stations/constants'
import classes from './app-shell.module.css'

export interface AppShellProps extends LogoProps {
  disableSearch?: boolean
}

export interface OutletContextProps {
  closeDesktopMenu: VoidFunction
  isWideContainer: boolean
}

export const AppShell = ({ logoImageUrl, logoImageAltText, disableSearch }: AppShellProps) => {
  const theme = useMantineTheme()
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints?.md})`)

  // Used to lock scroll on body element when mobile menu open
  const { lock, unlock } = useScrollLock({
    autoLock: false,
  })

  // Make sure scroll restored if viewport resized after mobile menu opened
  useEffect(() => {
    if (isDesktop) {
      unlock()
    }
  }, [isDesktop])

  /*
   * References to open/close buttons on mobile so we can shift
   * keyboard focus appropriately on open/close.
   */
  const mobileCloseBtn = useRef<HTMLButtonElement>(null)
  const mobileOpenBtn = useRef<HTMLButtonElement>(null)
  const triggerFocusOnCloseBtn = () => mobileCloseBtn.current?.focus()
  const triggerFocusOnOpenBtn = () => mobileOpenBtn.current?.focus()

  const [mobileOpened, { toggle: toggleMobile, close: closeMobileMenu }] = useDisclosure(false, {
    onOpen: () => {
      /**
       * Timeout allows hidden attribute to be removed from menu
       * panel contents first (otherwise focus not possible)
       */
      setTimeout(triggerFocusOnCloseBtn, 100)
      if (!isDesktop) {
        lock()
      }
    },
    onClose: () => {
      triggerFocusOnOpenBtn()
      unlock()
    },
  })
  const [desktopOpened, { toggle: toggleDesktop, close: closeDesktopMenu }] = useDisclosure(true)

  // Used to prevent keyboard focus leaving mobile menu panel when open
  const focusTrapRef = useFocusTrap(true)

  const [params, containerRef] = useContainerQuery(containerQueries, {})

  const { searchTermEntry, setSearchTermEntry, handleSearchRequest } =
    useStationSearch(SEARCH_LIMIT)

  return (
    <>
      <a href='#a11y-main-content' className={classes.skipLink}>
        Skip to content
      </a>
      {!isDesktop && (
        <MobileHeader
          onClickMenuToggle={() => toggleMobile()}
          logoImageUrl={logoImageUrl}
          logoImageAltText={logoImageAltText}
          ref={mobileOpenBtn}
        />
      )}
      <MantineAppShell
        navbar={{
          width: { md: 300 },
          breakpoint: 'md',
          collapsed: { mobile: !mobileOpened, desktop: !desktopOpened },
        }}
        padding={0}
        classNames={{
          navbar: classes.navBar,
          main: classes.mainAppContainer,
        }}
        withBorder={false}
      >
        <MantineAppShell.Navbar aria-label='Main'>
          <ErrorBoundary fallbackRender={MenuError}>
            {/*
             * Apply hidden attribute to prevent keyboard access/exposing
             * elements to a11y tree when panel visually hidden
             */}
            <div
              className={classes.menuContainer}
              data-testid='menu-container'
              hidden={(isDesktop && !desktopOpened) || (!isDesktop && !mobileOpened)}
              // Create focus trap within mobile menu panel when open:
              ref={!isDesktop && mobileOpened ? focusTrapRef : null}
            >
              {!isDesktop && (
                <MobileHeader
                  onClickMenuToggle={() => toggleMobile()}
                  closeMobileMenu={closeMobileMenu}
                  logoImageUrl={logoImageUrl}
                  logoImageAltText={logoImageAltText}
                  menuIcon='cross'
                  ref={mobileCloseBtn}
                />
              )}
              <Stack gap='xl' align='start' className={classes.navBarContent}>
                {isDesktop && (
                  <Logo
                    logoImageUrl={logoImageUrl}
                    logoImageAltText={logoImageAltText}
                    closeMobileMenu={closeMobileMenu}
                  />
                )}
                {!disableSearch && (
                  // Use a div here to prevent the search input from pushing the menu items down
                  <div>
                    <Search
                      placeholder='Search stations'
                      fullWidth
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleSearchRequest()
                        }
                      }}
                      onChange={(e) => setSearchTermEntry(e.currentTarget.value)}
                      value={searchTermEntry}
                    />
                  </div>
                )}
                <MainMenu links={MAIN_MENU_LINKS} closeMobileMenu={closeMobileMenu} />
                <UserMenu closeMobileMenu={closeMobileMenu} />
              </Stack>
            </div>
            {isDesktop && (
              <button
                type='button'
                className={classes.toggleDesktopMenuBtn}
                onClick={toggleDesktop}
              >
                {desktopOpened ? <IconChevronLeft /> : <IconMenu2 />}
              </button>
            )}
          </ErrorBoundary>
        </MantineAppShell.Navbar>
        <MantineAppShell.Main id='a11y-main-content'>
          <div ref={containerRef} className={classes.mainAppContainerInner}>
            <ErrorBoundary fallbackRender={ErrorPage}>
              <Outlet
                context={{
                  closeDesktopMenu,
                  isWideContainer: params.isWideContainer,
                }}
              />
            </ErrorBoundary>
          </div>
        </MantineAppShell.Main>
      </MantineAppShell>
    </>
  )
}
