import { Container, Group, Image, Paper, Stack, Text } from '@mantine/core'
import homePageLogo from '@/assets/home-logo.svg'
import { OfferFormLotCard } from '@/components/offers/offer-form-lot-card/offer-form-lot-card'
import { MutationCreateOfferArgsAllowUndefined } from '@/components/offers/offer-types'
import { formatDate } from '@shared/helpers/format-date/format-date'

export type OfferFormProps = {
  readOnly: boolean
  brand: string
  formData: MutationCreateOfferArgsAllowUndefined
}

/**
 * OfferForm component displays an offer for wool, including details such as brand, expiry date, payment date, and a personalized comment.
 *
 * @param {boolean} readOnly - A flag indicating if the form is in read-only mode. Defaults to false.
 * @param {string} brand - The brand associated with the offer.
 * @param {string} personalisedComment - A personalized comment to be displayed in the offer.
 * @param {string} from - The name of the person sending the offer.
 * @param {Date} expiryDatetime - The expiry date and time of the offer.
 * @param {Date} paymentDate - The date when the payment will be made if the offer is accepted.
 *
 * @returns {JSX.Element} The rendered OfferForm component.
 */
export const OfferForm = ({
  readOnly = false,
  brand,
  formData: { woolLotOfferModels, personalisedComment, from, expiryDateTime, paymentDate },
}: OfferFormProps) => {
  // Temporary attribute to prevent issues
  if (!readOnly) {
    throw new Error('OfferForm is not yet designed to be used in read-only=false mode')
  }

  const formattedExpiryDatetime = formatDate(expiryDateTime, 'h:mm A on Do MMMM YYYY')
  const formattedPaymentDate = formatDate(paymentDate, 'Do MMMM YYYY')

  return (
    <Container px={0} aria-label='Offer preview'>
      <Paper withBorder p='lg'>
        <Stack>
          <Group justify='space-between'>
            <Image src={homePageLogo} alt='New Zealand Merino' w={300} />
            <Text>
              Brand:{' '}
              <Text span fw={700}>
                {brand}
              </Text>
            </Text>
          </Group>
          <Text>
            We are pleased to present you the following offer on your wool. If you accept, the
            payment will be made on {formattedPaymentDate}.
          </Text>
          <Text>
            Please note that{' '}
            <Text span fw={700}>
              this offer expires at {formattedExpiryDatetime}
            </Text>
            .
          </Text>
          <Text>All prices are shown in cents per kg.</Text>
          <Text>{personalisedComment}</Text>
          {woolLotOfferModels?.map((lot, index) => (
            <OfferFormLotCard key={index} lot={lot} index={index} />
          ))}
          <Text>
            If you have any questions regarding this offer please contact myself or your Area
            Manager.
          </Text>

          <Text>Kind regards,</Text>

          <Text>{from}</Text>

          <Text>The NZM Commercial Team - Sent on behalf of the New Zealand Merino Company</Text>
        </Stack>
      </Paper>
    </Container>
  )
}
