import { gql } from 'graphql-request'

export const GetNotesAndContactsGql = gql`
  query notesAndContacts($recordNumber: String!, $populateAccountManager: Boolean!) {
    notesAndContacts(
      stationRecordNumber: $recordNumber
      populateAccountManager: $populateAccountManager
    ) {
      offerNotes
      offerContactOptions {
        firstName
        lastName
        roles
        email
        mobilePhone
        crmLink
        sendEmail
        sendSms
        sendReminders
        isStaffMember
      }
    }
  }
`
