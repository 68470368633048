import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Group, Stack, Title } from '@mantine/core'
import { SeasonDropdown } from '@/components/shared/season-dropdown/season-dropdown'
import { ReceivalHistoryTable } from '@/components/stations/wool-data/receival-history-table/receival-history-table'
import { GetReceivalHistoryGql } from '@/graphql/queries/get-receival-history.graphql'
import { toLocalDate } from '@shared/helpers/format-date/format-date'
import { useAuthenticatedGraphQuery } from '@/hooks/use-authenticated-query'

export function ReceivalHistory() {
  // Get the station record number from the URL
  const { stationRecordNumber } = useParams()

  // State for the season start and end dates
  const [seasonStartDate, setSeasonStartDate] = useState<Date | undefined>(undefined)
  const [seasonEndDate, setSeasonEndDate] = useState<Date | undefined>(undefined)

  // Convert Date objects to ISO date strings for GraphQL
  const { fromDateReceived, toDateReceived } = useMemo(
    () => ({
      fromDateReceived: toLocalDate(seasonStartDate),
      toDateReceived: toLocalDate(seasonEndDate),
    }),
    [seasonStartDate, seasonEndDate]
  )

  // Get the receival history data
  const { data, isLoading, isError } = useAuthenticatedGraphQuery({
    queryKey: ['receivalHistoryData', stationRecordNumber, fromDateReceived, toDateReceived],
    gql: GetReceivalHistoryGql,
    queryParams: {
      stationRecordNumber,
      fromDateReceived,
      toDateReceived,
    },
    enabled: !!stationRecordNumber && !!fromDateReceived && !!toDateReceived,
  })

  return (
    <Stack>
      <Group justify='space-between'>
        <Title order={2} data-testid='station-wool-data-receival-history'>
          Receival History
        </Title>
        <SeasonDropdown
          seasonsBeforeCurrent={5}
          setSeasonStartDate={setSeasonStartDate}
          setSeasonEndDate={setSeasonEndDate}
        />
      </Group>
      <ReceivalHistoryTable
        data={data?.listReceivalHistory?.results ?? []}
        sumOfTotalBalesReceived={data?.listReceivalHistory?.sumOfTotalBalesReceived ?? null}
        isLoading={isLoading}
        isError={isError}
      />
    </Stack>
  )
}
