import { useContext, useEffect, useMemo, useState } from 'react'
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_Row,
  MRT_RowSelectionState,
  MRT_TableInstance,
  useMantineReactTable,
} from 'mantine-react-table'
import { unsoldLotsColumns } from '@/components/offers/unsold-lots-columns'
import {
  hubTableDefaultAlertBannerProps,
  hubTableDefaultProps,
} from '@/components/shared/table/table'
import { NoDataMessage } from '@/components/shared/table/table-no-data-message'
import { LoomWoolLotOfferStatus, UnsoldLot } from '@loom-api-types'
import { OffersContext } from '@/pages/offers/offers-context'

interface UnsoldLotsTableProps {
  data: UnsoldLot[]
  noDataMessage?: string
  isLoading?: boolean
  isError?: boolean
}

export const UnsoldLotsTable = ({
  data = [],
  noDataMessage = 'No unsold lots available',
  isLoading,
  isError,
}: UnsoldLotsTableProps) => {
  const { setOfferData } = useContext(OffersContext)
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({})
  const [currentStationRecordNumber, setCurrentStationRecordNumber] = useState<string | null>(null)

  let table: MRT_TableInstance<UnsoldLot> | null = null

  // Set the current station record number when the first row is selected
  useEffect(() => {
    const selectedRows = table?.getSelectedRowModel()?.rows?.map((row) => row.original) ?? []
    if (selectedRows.length > 0) {
      const firstSelectedLot = selectedRows[0]
      setOfferData({
        stationBrand: firstSelectedLot.stationBrand ?? '',
        stationRecordNumber: firstSelectedLot.stationRecordNumber ?? '',
        offerLots: selectedRows,
      })
      setCurrentStationRecordNumber(firstSelectedLot.stationRecordNumber ?? null)
    } else {
      setCurrentStationRecordNumber(null)
      setOfferData(null)
    }
  }, [rowSelection])

  const rowSelectionMessage = () => {
    if (Object.keys(rowSelection).length === 0) return 'No lots selected'
    if (Object.keys(rowSelection).length === 1) return '1 lot selected'
    return `${Object.keys(rowSelection).length} lots selected`
  }

  const columns = useMemo<MRT_ColumnDef<UnsoldLot>[]>(() => unsoldLotsColumns, [])

  // Only allow selection of lots if they are not under offer and are from the same station
  const enableRowSelection = (row: UnsoldLot) =>
    (Object.keys(rowSelection).length === 0 ||
      row.stationRecordNumber === currentStationRecordNumber) &&
    row.status !== LoomWoolLotOfferStatus.UnderOffer

  table = useMantineReactTable({
    ...hubTableDefaultProps,
    columns,
    data,
    initialState: {
      ...hubTableDefaultProps.initialState,
      showColumnFilters: true,
      columnPinning: { left: ['mrt-row-select'], right: ['status'] },
    },
    state: {
      isLoading,
      showAlertBanner: isError,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: hubTableDefaultAlertBannerProps(isError),
    renderEmptyRowsFallback: () => <NoDataMessage noDataMessage={noDataMessage} />,
    mantineTableProps: {
      ...hubTableDefaultProps.mantineTableProps,
      'aria-label': 'Unsold Lots',
    },
    // Clicking anywhere on the row will select it
    mantineTableBodyRowProps: ({ row }) => {
      const props = {
        onClick: row.getToggleSelectedHandler(),
        style: { cursor: 'pointer' },
      }
      const underOffer = row.original.status === LoomWoolLotOfferStatus.UnderOffer
      return underOffer
        ? { ...props, bg: 'gray.1', c: 'gray.5', style: { cursor: 'not-allowed' } }
        : props
    },
    // Pin important columns
    enableColumnPinning: true,
    // Column filter settings
    enableFilters: true,
    enableFacetedValues: true,
    // Row selection settings
    enableRowSelection: (row: MRT_Row<UnsoldLot>) => enableRowSelection(row.original),
    enableSelectAll: false,
    onRowSelectionChange: setRowSelection,
    getRowId: (row: UnsoldLot) => row.internalReferenceCode ?? undefined,
    renderToolbarAlertBannerContent() {
      return isError ? hubTableDefaultAlertBannerProps(isError)?.children : rowSelectionMessage()
    },
  })

  return <MantineReactTable table={table} />
}
