import { IconSearch } from '@tabler/icons-react'
import clsx from 'clsx'
import { TextInput, TextInputProps } from '@mantine/core'
import classes from './search.module.css'

export const Search = ({ fullWidth, ...props }: TextInputProps & { fullWidth?: boolean }) => {
  const searchClasses = clsx(classes.input, { [classes.inputFullWidth]: fullWidth })
  return (
    <TextInput
      aria-label='Search'
      type='search'
      size='sm'
      leftSectionPointerEvents='none'
      leftSection={<IconSearch className={classes.searchIcon} />}
      classNames={{
        root: searchClasses,
      }}
      {...props}
    />
  )
}
