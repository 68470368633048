import {
  IconBoxMultiple,
  IconBuildingCottage,
  IconFileTextSpark,
  IconScan,
  IconTools,
} from '@tabler/icons-react'
import { NavLink } from 'react-router-dom'
import { Box, Grid, Paper, rem, Stack, Title } from '@mantine/core'
import homePageLogo from '@/assets/home-logo.svg'
import { PageContainer } from '@/components/shared/app-shell/page-container/page-container'
import { PAGE_TITLES } from '@/constants/page-titles'

export function HomePage() {
  return (
    <PageContainer title={PAGE_TITLES.HOME}>
      <Stack>
        <Title order={1}>
          <img src={homePageLogo} alt='New Zealand Merino' />
        </Title>
        <Title order={2}>Quick links</Title>
        {/*
          Note: Home Page/Dashboard content to be finalised.
          The following should be componentised/styles moved to
          CSS module files etc if design direction is retained.
        */}
        <Grid maw={rem(800)}>
          <Grid.Col span={{ base: 6, md: 4 }}>
            <Paper
              to='/stations'
              component={NavLink}
              shadow='sm'
              p='md'
              withBorder
              bd='1px solid gray.2'
            >
              <Stack gap='xl'>
                <Box c='blue.6'>
                  <IconBuildingCottage />
                </Box>
                <Title order={3} size='lg' c='black'>
                  Stations
                </Title>
              </Stack>
            </Paper>
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 4 }}>
            <Paper
              to='/offers'
              component={NavLink}
              shadow='sm'
              p='md'
              withBorder
              bd='1px solid gray.2'
            >
              <Stack gap='xl'>
                <Box c='blue.6'>
                  <IconFileTextSpark />
                </Box>
                <Title order={3} size='lg' c='black'>
                  Offers
                </Title>
              </Stack>
            </Paper>
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 4 }}>
            <Paper
              to='/wool-store-tools'
              component={NavLink}
              shadow='sm'
              p='md'
              withBorder
              bd='1px solid gray.2'
            >
              <Stack gap='xl'>
                <Box c='blue.6'>
                  <IconTools />
                </Box>
                <Title order={3} size='lg' c='black'>
                  Wool Store Tools
                </Title>
              </Stack>
            </Paper>
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 4 }}>
            <Paper
              to='/typing/program'
              component={NavLink}
              shadow='sm'
              p='md'
              withBorder
              bd='1px solid gray.2'
            >
              <Stack gap='xl'>
                <Box c='blue.6'>
                  <IconScan />
                </Box>
                <Title order={3} size='lg' c='black'>
                  Typing
                </Title>
              </Stack>
            </Paper>
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 4 }}>
            <Paper
              to='/typing/typed-lots'
              component={NavLink}
              shadow='sm'
              p='md'
              withBorder
              bd='1px solid gray.2'
            >
              <Stack gap='xl'>
                <Box c='blue.6'>
                  <IconBoxMultiple />
                </Box>
                <Title order={3} size='lg' c='black'>
                  Typed Lots
                </Title>
              </Stack>
            </Paper>
          </Grid.Col>
        </Grid>
      </Stack>
    </PageContainer>
  )
}
