import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'

// Allows advanced format strings
// Ref: https://day.js.org/docs/en/plugin/advanced-format
dayjs.extend(advancedFormat)

/**
 * Formats a given date string into a specified format.
 *
 * @param date - The date string to format. Can be `string`, `undefined`, or `null`.
 * @param format - The format string to apply to the date.
 * @returns The formatted date string, or an empty string if the input date is `undefined` or `null`.
 */
export function formatDate(date: Date | undefined | null, format: string) {
  if (!date) return ''
  return dayjs(date).format(format)
}

/**
 * Converts a JavaScript Date to a LocalDate string in YYYY-MM-DD format.
 * The year, month, and day values are preserved from the local date.
 *
 * @param date - The Date object to convert. Can be `undefined` or `null`.
 * @returns The LocalDate string in YYYY-MM-DD format, or `null` if the input date is `undefined` or `null`.
 */
export function toLocalDate(date: Date | undefined | null): string | null {
  if (!date) return null
  const year = date.getFullYear()
  // JavaScript's Date.getMonth() returns 0-11 (Jan-Dec), so we add 1 to get 1-12
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}
