import { PropsWithChildren } from 'react'
import { Stack, Text } from '@mantine/core'

type ValueGroupProps = {
  title: string | null
}

/**
 * A functional component that renders a group of values with a title.
 *
 * @param {PropsWithChildren<ValueGroupProps>} props - The props for the component.
 * @param {string} props.title - The title to display above the group of values.
 * @param {React.ReactNode} props.children - The child elements to be rendered within the group.
 * @returns {JSX.Element} The rendered ValueGroup component.
 */
export function ValueGroup({ title, children }: PropsWithChildren<ValueGroupProps>) {
  return (
    <Stack gap='xxs'>
      <Text size='xs' fw={600} c='gray.5'>
        {title}
      </Text>
      {children}
    </Stack>
  )
}
