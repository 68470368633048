import { PropsWithChildren } from 'react'
import { IconChevronDown } from '@tabler/icons-react'
import clsx from 'clsx'
import { Group, Collapse as MantineCollapse, Stack, Title, TitleOrder } from '@mantine/core'
import classes from './collapse.module.css'

type CollapseProps = {
  buttonLabel: string
  sectionHeading?: string
  sectionHeadingLevel?: TitleOrder
  isOpen: boolean
  toggle: () => void
}

/**
 * Collapse component that provides a toggleable section with a title.
 *
 * @param {PropsWithChildren<CollapseProps>} props - The properties for the Collapse component.
 * @param {string} props.buttonLabel - The label of the collapsible section toggle button. Displays with a 'Show' or 'Hide' prefix.
 * @param {string} props.sectionHeading - Optional text to be displayed as section heading (opposite toggle button).
 * @param {TitleOrder} props.sectionHeadingLevel - Optional setting to ensure appropriate heading level can be used when heading present (note: typography styling stays the same irrespective of heading level).
 * @param {boolean} props.isOpen - dictates whether the component is expanded or collapsed
 * @param {VoidFunction} props.toggle - toggles the `isOpen` state
 * @param {React.ReactNode} props.children - The content to be displayed within the collapsible section.
 *
 * @returns {JSX.Element} The rendered Collapse component.
 *
 * @example
 * ```tsx
 * import { Collapse } from './collapse';
 *
 * function App() {
 *   return (
 *     <Collapse sectionHeading="Name of collapsible section" buttonLabel="More Information" isOpen={isOpen} toggle={setIsOpen(!isOpen)}>
 *       <p>This is the content that can be toggled.</p>
 *     </Collapse>
 *   );
 * }
 * ```
 */
export function Collapse({
  buttonLabel,
  sectionHeading,
  sectionHeadingLevel = 2,
  children,
  isOpen,
  toggle,
}: PropsWithChildren<CollapseProps>) {
  const iconClasses = clsx(classes.toggleIcon, {
    [classes.toggleIconRotated]: isOpen,
  })

  return (
    <Stack>
      <Group justify='space-between'>
        {sectionHeading && (
          <Title order={sectionHeadingLevel} size='h3'>
            {sectionHeading}
          </Title>
        )}
        <button type='button' className={classes.toggleButton} onClick={toggle}>
          <IconChevronDown className={iconClasses} />
          {isOpen ? 'Hide' : 'Show'} {buttonLabel}
        </button>
      </Group>
      <MantineCollapse in={isOpen}>{children}</MantineCollapse>
    </Stack>
  )
}
