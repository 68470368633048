import { AlertLevel, IconAlert } from '@/components/shared/icon-alert'
import { ErrorWithResponse } from '@/hooks/use-authenticated-query'
import { CRMErrors } from '@/pages/stations/details/station-details.page'

export interface CRMErrorAlertsProps {
  crmError: ErrorWithResponse | null
  notFoundIsError?: boolean
}

export function CRMErrorAlerts({ crmError, notFoundIsError = true }: CRMErrorAlertsProps) {
  // The error object from the useQuery has the full response object attached to it
  // though it is typed as an Error. Throwing an exception from the GraphQL handler on the server
  // results in a 200 response with a list of errors in the response object.
  const crmErrorCode = (error: ErrorWithResponse): string =>
    error.response?.errors?.[0]?.extensions?.code ?? ''

  const crmErrorMessage = (error: ErrorWithResponse): string =>
    error.response?.errors?.[0]?.message ?? ''

  return (
    <>
      {crmError && crmErrorCode(crmError) === CRMErrors.NotFound && (
        <IconAlert
          level={notFoundIsError ? AlertLevel.ERROR : AlertLevel.WARNING}
          title='CRM information not found'
          message={crmErrorMessage(crmError)}
        />
      )}
      {crmError && crmErrorCode(crmError) === CRMErrors.Ambiguous && (
        <IconAlert
          level={AlertLevel.ERROR}
          title='CRM information ambiguous'
          message={crmErrorMessage(crmError)}
        />
      )}
      {crmError && !Object.keys(CRMErrors).includes(crmErrorCode(crmError)) && (
        <IconAlert
          level={AlertLevel.ERROR}
          title='CRM request failed'
          message={crmErrorMessage(crmError)}
        />
      )}
    </>
  )
}
