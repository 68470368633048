import { useMemo } from 'react'
import { MantineReactTable, MRT_ColumnDef, useMantineReactTable } from 'mantine-react-table'
import { Anchor, Checkbox, Group, Pill } from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'
import { hubTableDefaultProps } from '@/components/shared/table/table'
import { NoDataMessage } from '@/components/shared/table/table-no-data-message'
import { OfferContactOption } from '@loom-api-types'
import { OfferEditorData } from '@/pages/offers/create-offer/create-offer.page'

interface OfferRecipientsTableProps {
  data: OfferContactOption[]
  form?: UseFormReturnType<OfferEditorData, (values: OfferEditorData) => OfferEditorData> | null
  noDataMessage?: string
  isLoading?: boolean
}

export const OfferRecipientsTable = ({
  data = [],
  form = null,
  noDataMessage = 'No contacts available',
  isLoading,
}: OfferRecipientsTableProps) => {
  const columns = useMemo<MRT_ColumnDef<OfferContactOption>[]>(
    () => [
      {
        accessorKey: 'fullName',
        header: 'Full Name',
        Cell: ({ row }) => {
          const name = `${row.original.firstName} ${row.original.lastName}`
          if (row.original.crmLink) {
            return (
              <Anchor href={row.original.crmLink} target='_blank' size='sm'>
                {name}
              </Anchor>
            )
          }
          return name
        },
      },
      {
        accessorKey: 'roles',
        header: 'Role(s)',
        Cell: ({ cell }) => (
          <Group gap='xs'>
            {cell.getValue<string[]>()?.map((value) => (
              <Pill tt='capitalize' key={value}>
                {value}
              </Pill>
            ))}
          </Group>
        ),
      },
      {
        accessorKey: 'email',
        header: 'Email',
      },
      {
        accessorKey: 'mobilePhone',
        header: 'Phone',
      },
      {
        accessorKey: 'sendEmail',
        header: 'Email',
        Cell: ({ row }) => (
          <Checkbox
            disabled={
              !row.original.email || row.original.email === '' || row.original.isStaffMember
            }
            {...form?.getInputProps(`offerContactSelections.${row.index}.sendEmail`, {
              type: 'checkbox',
            })}
            key={form?.key(`offerContactSelections.${row.index}.sendEmail`)}
            aria-label={`Send emails to ${row.original.firstName} ${row.original.lastName}`}
            error={
              !!form?.errors?.offerContactSelections ||
              !!form?.errors[`offerContactSelections.${row.index}`]
            }
          />
        ),
      },
      {
        accessorKey: 'sendSms',
        header: 'SMS',
        Cell: ({ row }) => (
          <Checkbox
            disabled={!row.original.mobilePhone || row.original.mobilePhone === ''}
            {...form?.getInputProps(`offerContactSelections.${row.index}.sendSms`, {
              type: 'checkbox',
            })}
            key={form?.key(`offerContactSelections.${row.index}.sendSms`)}
            aria-label={`Send sms to ${row.original.firstName} ${row.original.lastName}`}
            error={
              !!form?.errors?.offerContactSelections ||
              !!form?.errors[`offerContactSelections.${row.index}`]
            }
          />
        ),
      },
      {
        accessorKey: 'sendReminders',
        header: 'Reminders',
        Cell: ({ row }) => (
          <Checkbox
            {...form?.getInputProps(`offerContactSelections.${row.index}.sendReminders`, {
              type: 'checkbox',
            })}
            key={form?.key(`offerContactSelections.${row.index}.sendReminders`)}
            aria-label={`Send reminders to ${row.original.firstName} ${row.original.lastName}`}
          />
        ),
      },
    ],
    [form?.errors]
  )

  const table = useMantineReactTable({
    ...hubTableDefaultProps,
    columns,
    data,
    state: {
      isLoading,
    },
    renderEmptyRowsFallback: () => <NoDataMessage noDataMessage={noDataMessage} />,
    mantineTableProps: {
      ...hubTableDefaultProps.mantineTableProps,
      'aria-label': 'Offer recipients',
    },
    enablePagination: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
  })

  return <MantineReactTable table={table} />
}
