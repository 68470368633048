import {
  AuthenticationResult,
  Configuration,
  EventType,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser'
import { configService } from '@/services/config-service'

// Config object to be passed to Msal on creation
const msalConfig: Configuration = {
  auth: {
    clientId: configService().get('MSAL_CLIENT_ID'),
    authority: configService().get('MSAL_AUTHORITY'),
    redirectUri: configService().get('MSAL_REDIRECT_URI'),
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    allowPlatformBroker: false, // Disables WAM Broker
  },
}

// Only log auth events for debugging in dev
if (configService().get('DEBUG_AUTH') === 'TRUE' && msalConfig.system) {
  msalConfig.system.loggerOptions = {
    loggerCallback: (level, message, containsPii) => {
      if (containsPii) {
        return
      }
      switch (level) {
        case LogLevel.Error:
          // eslint-disable-next-line no-console
          console.error(message)
          return
        case LogLevel.Info:
          // eslint-disable-next-line no-console
          console.info(message)
          return
        case LogLevel.Verbose:
          // eslint-disable-next-line no-console
          console.debug(message)
          return
        case LogLevel.Warning:
          // eslint-disable-next-line no-console
          console.warn(message)
      }
    },
  }
}

// These Scopes are defined in Entra at the API App Registration under the "Expose an API" section.
// The client App Registration is configured as an "Authorised client" for the API App Registration.
// The client App Registration also has to be configured to use specific scopes from the API App registration in it's "API Permissions" section.
export const loginRequest = {
  scopes: [`${configService().get('MSAL_LOOM_APP_URI')}/Api.Read`],
}

// Our customised way of initializing the PublicClientApplication for MSAL
// This handler controls the automatic login behaviour on page load
export const createMsalInstance = async () => {
  const msalInstance = new PublicClientApplication(msalConfig)
  await msalInstance.initialize()

  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
  }

  msalInstance.addEventCallback((event) => {
    const payload = event?.payload as AuthenticationResult
    if (event.eventType === EventType.LOGIN_SUCCESS && payload?.account) {
      msalInstance.setActiveAccount(payload?.account)
    }
  })

  return msalInstance
}
