import { IconAlertTriangle } from '@tabler/icons-react'
import { Group, MantineSpacing, Pill, StyleProp } from '@mantine/core'

type IconPillProps = {
  text: string
  ml?: StyleProp<MantineSpacing>
  hideIcon?: boolean
}

export function PillAlert({ text, ml, hideIcon }: IconPillProps) {
  return (
    <Pill ml={ml} bg='red.2' fw={600} aria-label={`Alert regarding ${text}`}>
      <Group wrap='nowrap' gap='xxs'>
        {!hideIcon && <IconAlertTriangle size={14} stroke={2} />}
        {text}
      </Group>
    </Pill>
  )
}
