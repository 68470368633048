import { ContractedLotResult } from '@loom-api-types'

export const CONTRACTED_LOT_RESULT_LABELS = {
  [ContractedLotResult.Accepted]: 'Accepted',
  [ContractedLotResult.ToBeApproved]: 'To be approved',
  [ContractedLotResult.YetToDeliver]: 'Yet to deliver',
}

/**
 * Converts a contracted lot result value to its corresponding string label.
 *
 * @param result
 * @returns
 */
export const contractedLotResultToString = (result: ContractedLotResult | null): string =>
  (result && CONTRACTED_LOT_RESULT_LABELS[result]) || 'Unknown'
