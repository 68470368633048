import { forwardRef } from 'react'
import { IconMenu2, IconX } from '@tabler/icons-react'
import { ActionIcon } from '@mantine/core'
import { Logo, LogoProps } from '@/components/shared/app-shell/logo/logo'
import classes from './mobile-header.module.css'

export interface mobileHeaderProps extends LogoProps {
  menuIcon?: 'cross' | 'hamburger'
  onClickMenuToggle: VoidFunction
}

export const MobileHeader = forwardRef(
  (
    {
      menuIcon = 'hamburger',
      onClickMenuToggle,
      closeMobileMenu,
      logoImageUrl,
      logoImageAltText,
    }: mobileHeaderProps,
    ref: React.Ref<HTMLButtonElement> | null
  ) => (
    <div className={classes.mobileHeader}>
      <Logo
        logoImageUrl={logoImageUrl}
        logoImageAltText={logoImageAltText}
        closeMobileMenu={closeMobileMenu}
      />
      <ActionIcon
        size={42}
        variant='subtle'
        aria-label='Open main menu'
        onClick={onClickMenuToggle}
        ref={ref}
      >
        {menuIcon === 'cross' ? <IconX /> : <IconMenu2 />}
      </ActionIcon>
    </div>
  )
)
