import { gql } from 'graphql-request'

export const GetStationGroupGql = gql`
  query stationGroupForOffers($recordNumber: String!) {
    stationHeader(recordNumber: $recordNumber) {
      stationsInGroup {
        recordNumber
      }
    }
  }
`
