import { Outlet } from 'react-router-dom'
import { OffersContextProvider } from '@/pages/offers/offers-context'

export default function OffersLayout() {
  return (
    <OffersContextProvider>
      <Outlet />
    </OffersContextProvider>
  )
}
