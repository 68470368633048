import { useEffect, useState } from 'react'
import { ColumnFiltersState, PaginationState, SortingState } from '@tanstack/react-table'
import { useParams, useSearchParams } from 'react-router-dom'
import { Group, Stack, Title } from '@mantine/core'
import { SeasonDropdown } from '@/components/shared/season-dropdown/season-dropdown'
import { DEFAULT_PAGINATION_SIZE } from '@/components/shared/table/table'
import { BaleDetailsTable } from '@/components/stations/wool-data/bale-details-table/bale-details-table'
import { GetBaleDetailsGql } from '@/graphql/queries/get-bale-details.graphql'
import { useAuthenticatedGraphQuery } from '@/hooks/use-authenticated-query'
import { ListBaleDetailsOrderByOptions } from '@loom-api-types'

export function BaleDetails() {
  // Get the values from the URL
  const { stationRecordNumber } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const referenceNumberURLValue = searchParams.get('referenceNumber')
  const seasonURLValue = searchParams.get('season')

  // Set the initial sorting state
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: ListBaleDetailsOrderByOptions.BaleNumber,
      desc: false,
    },
  ])

  // Set the initial pagination state
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: DEFAULT_PAGINATION_SIZE,
  })

  // State for the season start date
  const [seasonStartDate, setSeasonStartDate] = useState<Date | undefined>(undefined)

  // Reference number column filter
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    referenceNumberURLValue
      ? [
          {
            id: 'referenceNumber',
            value: referenceNumberURLValue,
          },
        ]
      : []
  )

  // Update the URL search params when filters change
  useEffect(() => {
    // If the season is different to the URL value
    if (seasonStartDate && seasonStartDate.getFullYear().toString() !== seasonURLValue) {
      searchParams.set('season', seasonStartDate.getFullYear().toString().substring(2))
      setSearchParams(searchParams, { replace: true })
    }
  }, [seasonStartDate])

  useEffect(() => {
    const referenceNumberFilter = columnFilters.find((filter) => filter.id === 'referenceNumber')
    // If the filter is different to the URL value
    if (referenceNumberFilter && referenceNumberFilter.value !== referenceNumberURLValue) {
      searchParams.set('referenceNumber', referenceNumberFilter.value as string)
      setSearchParams(searchParams, { replace: true })
    }
    // If the filter was cleared and URL has value
    if (!referenceNumberFilter && referenceNumberURLValue) {
      searchParams.delete('referenceNumber')
      setSearchParams(searchParams, { replace: true })
    }
  }, [columnFilters])

  // Get the receival history data
  const { data, isLoading, isError } = useAuthenticatedGraphQuery({
    queryKey: [
      'baleDetailData',
      stationRecordNumber,
      sorting,
      pagination,
      columnFilters,
      seasonStartDate,
    ],
    gql: GetBaleDetailsGql,
    queryParams: {
      stationRecordNumber,
      currentPage: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
      orderBy: sorting[0]?.id || ListBaleDetailsOrderByOptions.BaleNumber,
      orderByAscending: !sorting[0]?.desc || false,
      referenceNumber: columnFilters.find((dict) => dict.id === 'referenceNumber')?.value as string,
      baleNumber: columnFilters.find((dict) => dict.id === ListBaleDetailsOrderByOptions.BaleNumber)
        ?.value as string,
      season: seasonStartDate?.getFullYear().toString().substring(2),
    },
    enabled: !!stationRecordNumber && !!seasonStartDate,
  })

  return (
    <Stack>
      <Group justify='space-between'>
        <Title order={2} data-testid='station-wool-data-bale-details'>
          Bale Details
        </Title>
        <SeasonDropdown
          seasonsBeforeCurrent={5}
          seasonsAfterCurrent={1}
          setSeasonStartDate={setSeasonStartDate}
          setSeasonEndDate={() => undefined} // Season end date is currently not used by this page
          initialSeason={seasonURLValue ? `20${seasonURLValue}` : undefined}
        />
      </Group>
      <BaleDetailsTable
        data={data?.listBaleDetails ?? null}
        isLoading={isLoading}
        isError={isError}
        sorting={sorting}
        setSorting={setSorting}
        pagination={pagination}
        setPagination={setPagination}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
      />
    </Stack>
  )
}
