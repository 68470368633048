import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { AccountInfo } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { IconMailForward, IconTrash } from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'
import {
  Button,
  Flex,
  Grid,
  Group,
  Loader,
  Modal,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core'
import { DateInput } from '@mantine/dates'
import { useForm, zodResolver } from '@mantine/form'
import { useDebouncedCallback, useDisclosure } from '@mantine/hooks'
import { OfferForm } from '@/components/offers/offer-form/offer-form'
import { OfferLotCard } from '@/components/offers/offer-lot-card/offer-lot-card'
import { OfferRecipientsTable } from '@/components/offers/offer-recipients-table/offer-recipients-table'
import { MutationCreateOfferArgsAllowUndefined } from '@/components/offers/offer-types'
import { PageContainer } from '@/components/shared/app-shell/page-container/page-container'
import { AlertLevel, IconAlert } from '@/components/shared/icon-alert'
import { PAGE_TITLES } from '@/constants/page-titles'
import { CreateOfferGql } from '@/graphql/mutations/create-offer.graphql'
import { GetNotesAndContactsGql } from '@/graphql/queries/offers/get-notes-and-contacts.graphql'
import { GetStationGroupGql } from '@/graphql/queries/offers/get-station-group.graphql'
import { toLocalDate } from '@shared/helpers/format-date/format-date'
import {
  showErrorNotification,
  showSuccessNotification,
} from '@shared/helpers/notifications/notifications'
import { isTestedLot } from '@/helpers/offer-prices/offer-prices'
import {
  useAuthenticatedGraphMutation,
  useAuthenticatedGraphQuery,
} from '@/hooks/use-authenticated-query'
import {
  CreateOfferResponse,
  OfferContactOption,
  OfferContactSelectionInput,
  OfferValidationFailureReason,
  WoolLotOfferModelInput,
} from '@loom-api-types'
import { OFFER_PREVIEW_DEBOUNCE_TIME } from '@/pages/offers/offer-constants'
import { OffersContext } from '@/pages/offers/offers-context'
import { CRMErrorAlerts } from './crm-error-alerts'

// It is required to extend dayjs with customParseFormat plugin
// in order to parse dates with custom format
// Ref: https://mantine.dev/dates/date-input/#with-time
dayjs.extend(customParseFormat)
export const EXPECTED_DATE_FORMAT: string = 'DD/MM/YY'
export const EXPECTED_DATETIME_FORMAT: string = 'DD/MM/YY h:mm A'

export type OfferLotInfo = {
  isTested: boolean
  cleanOfferPrice: number | null
  cleanMarketPrice: number | null
  greasyOfferPrice: number | null
  greasyMarketPrice: number | null
  valueAdd: number | null
  displayValueAdd: boolean
  brandPartner: string | null
}

// TODO: Replace with MutationCreateOfferArgsAllowUndefined
export type OfferEditorData = {
  offerLotsInfo: OfferLotInfo[]
  offerContactSelections: OfferContactSelectionInput[]
  expiryDateTime?: Date
  paymentDate?: Date
  personalisedComment?: string
  from?: string
}

const EMPTY_CONTACT_SELECTIONS_ERROR_MESSAGE =
  'At least one recipient must have email or sms enabled (excluding NZM staff)'
const REMINDERS_ERROR_MESSAGE =
  'If reminders are enabled, at least one of email or sms must be selected'

export function CreateOfferPage() {
  const navigate = useNavigate()
  const { offerData } = useContext(OffersContext)
  const [cancelModalState, { open: openCancelModal, close: closeCancelModal }] =
    useDisclosure(false)
  const [sendModalState, { open: openSendModal, close: closeSendModal }] = useDisclosure(false)

  const confirmCancel = () => {
    closeCancelModal()
    goToUnsoldLots()
  }

  // Get the details of the current user and add them as a contact option
  const { instance } = useMsal()
  const account: AccountInfo | null = useMemo(() => instance.getActiveAccount(), [instance])

  const createOfferMutation = useAuthenticatedGraphMutation({
    gql: CreateOfferGql,
    gqlKey: 'createOffer',
    onSuccess: (mutation) => {
      const responseData: CreateOfferResponse = mutation.createOffer

      if (responseData.offerCreationSuccess) {
        // show a toast notification to show that the offer was sent
        showSuccessNotification('Offer sent successfully')

        goToUnsoldLots()
      } else {
        // switch on the offerValidationFailureReason to get the correct error message
        let errorMessage = 'Error: '
        switch (responseData.offerValidationFailureReason) {
          case OfferValidationFailureReason.DuplicateWoolLotIds:
            errorMessage += 'Duplicate wool lot ids'
            break
          case OfferValidationFailureReason.MoreThanOneStation:
            errorMessage += 'More than one station in the offer'
            break
          case OfferValidationFailureReason.NoWoolLotOfferModels:
            errorMessage += 'No wool lots selected for offer'
            break
          case OfferValidationFailureReason.WoolLotsAlreadyUnderOfferOrAccepted:
            errorMessage += 'One or more wool lots are already under offer or accepted'
            break
          default:
            errorMessage += 'Offer validation failed'
        }

        showErrorNotification(errorMessage)
      }
    },
  })
  // We need to determine if the station is part of a station group
  const { data: stationData, isSuccess: stationSuccess } = useAuthenticatedGraphQuery({
    queryKey: ['stationHeader', offerData?.stationRecordNumber],
    gql: GetStationGroupGql,
    queryParams: { recordNumber: offerData?.stationRecordNumber },
    enabled: !!offerData?.stationBrand,
  })
  // TODO: Add error handling for stationHeader endpoint
  // If the station is not found, the response is null, not an error.
  const stationError = stationSuccess && !stationData?.stationHeader

  // Get Crm data required for offer
  const {
    data: notesAndContactsQuery,
    error: crmError,
    isLoading: crmIsLoading,
    isFetching: crmIsFetching,
    refetch: refetchCrmData,
  } = useAuthenticatedGraphQuery({
    queryKey: ['notesAndContacts', offerData?.stationRecordNumber],
    gql: GetNotesAndContactsGql,
    queryParams: {
      recordNumber: offerData?.stationRecordNumber,
      populateAccountManager: (stationData?.stationHeader?.stationsInGroup?.length ?? 0) > 0,
    },
    enabled: !!offerData && !!stationData,
    retry: false,
  })

  const buildOfferData = (): MutationCreateOfferArgsAllowUndefined => {
    const { offerLotsInfo, offerContactSelections, paymentDate, ...remainingFormValues } =
      form.getValues()

    const woolLotOfferModels: WoolLotOfferModelInput[] | undefined = offerData?.offerLots.map(
      (lot, index) => {
        // Exclude any properties that are not required for the input
        const { status, ...offerLotForInput } = lot
        // Extract the form values (excluding isTested)
        const { isTested, ...lotFormValues } = offerLotsInfo[index]
        // Return the WoolLotOfferModelInput with the form values added
        return {
          ...offerLotForInput,
          ...lotFormValues,
        }
      }
    )

    const selectedOfferContacts: OfferContactSelectionInput[] = offerContactSelections.filter(
      (selection) => selection.sendEmail || selection.sendSms
    )

    return {
      ...remainingFormValues,
      woolLotOfferModels,
      offerContactSelections: selectedOfferContacts,
      paymentDate: toLocalDate(paymentDate),
    }
  }

  const confirmSend = async () => {
    closeSendModal()
    const offerRequestModel = buildOfferData()
    createOfferMutation.mutate(offerRequestModel)
  }

  const goToUnsoldLots = () => {
    navigate('/offers/unsold-lots')
  }

  // If there's no offerData in context, redirect to unsold lots panel by default
  useEffect(() => {
    if (offerData == null || !offerData.stationBrand) {
      goToUnsoldLots()
    }
  }, [offerData])

  // If either of the greasy prices are set, both must be set
  const greasyFieldsValid = (offerLotInfo: OfferLotInfo, key: keyof OfferLotInfo) => {
    if (offerLotInfo.isTested) return true

    const onlyGreasyOfferPrice = !!offerLotInfo.greasyOfferPrice && !offerLotInfo.greasyMarketPrice
    const onlyGreasyMarketPrice = !offerLotInfo.greasyOfferPrice && !!offerLotInfo.greasyMarketPrice

    if (key === 'greasyOfferPrice') return !onlyGreasyOfferPrice
    if (key === 'greasyMarketPrice') return !onlyGreasyMarketPrice
    return true
  }

  // If the lot is tested, clean prices are required
  // If the lot is untested, greasy prices are required
  const priceFieldValid = (offerLotInfo: OfferLotInfo, key: keyof OfferLotInfo) => {
    if (key.startsWith('clean') && !offerLotInfo.isTested) return true
    if (key.startsWith('clean')) return !!offerLotInfo[key]

    if (key.startsWith('greasy') && offerLotInfo.isTested) return true
    if (key.startsWith('greasy')) return !!offerLotInfo[key]

    return true
  }

  // If reminders are enabled, at least one of email or sms must be selected
  // If reminders are disabled, this validation is not required
  const remindersCheckboxValid = (contact: OfferContactOption) => {
    if (!contact.sendReminders) return true
    return contact.sendEmail || contact.sendSms
  }

  // Offer price validation schema
  const offerPrice = z.coerce
    .number()
    .lt(100000, { message: 'Price must be less than 100,000 cents per kilo' })
    .nullable()

  // Form validation schema
  const formSchema = z.object({
    expiryDateTime: z
      .date({
        required_error: 'An expiry date and time is required',
        invalid_type_error: 'Invalid date time value',
      })
      .refine((date) => date > new Date(), {
        message: 'Expiry must be in the future',
      }),
    from: z.string().min(1, { message: 'A from value is required' }),
    paymentDate: z
      .date({
        required_error: 'A payment date is required',
        invalid_type_error: 'Invalid date value',
      })
      .refine((date) => date > new Date(), {
        message: 'Payment must be in the future',
      }),
    offerLotsInfo: z.array(
      z
        .object({
          isTested: z.boolean(),
          greasyOfferPrice: offerPrice,
          cleanOfferPrice: offerPrice,
          greasyMarketPrice: offerPrice,
          cleanMarketPrice: offerPrice,
          valueAdd: z.number().nullable(),
          displayValueAdd: z.boolean(),
          brandPartner: z.string().nullable(),
        })
        .refine((lot) => priceFieldValid(lot, 'cleanOfferPrice'), {
          message: 'Required for tested lots',
          path: ['cleanOfferPrice'],
        })
        .refine((lot) => priceFieldValid(lot, 'cleanMarketPrice'), {
          message: 'Required for tested lots',
          path: ['cleanMarketPrice'],
        })
        .refine((lot) => priceFieldValid(lot, 'greasyOfferPrice'), {
          message: 'Required for untested lots',
          path: ['greasyOfferPrice'],
        })
        .refine((lot) => priceFieldValid(lot, 'greasyMarketPrice'), {
          message: 'Required for untested lots',
          path: ['greasyMarketPrice'],
        })
        .refine((lot) => greasyFieldsValid(lot, 'greasyOfferPrice'), {
          message: 'Required if offer price set',
          path: ['greasyMarketPrice'],
        })
        .refine((lot) => greasyFieldsValid(lot, 'greasyMarketPrice'), {
          message: 'Required if market price set',
          path: ['greasyOfferPrice'],
        })
    ),
    offerContactSelections: z
      .array(
        z
          .object({
            firstName: z.string(),
            lastName: z.string(),
            roles: z.array(z.string()),
            sendEmail: z.boolean(),
            sendSms: z.boolean(),
            sendReminders: z.boolean(),
            isStaffMember: z.boolean(),
          })
          .refine((contact) => remindersCheckboxValid(contact), {
            message: REMINDERS_ERROR_MESSAGE,
          })
      )
      .nonempty()
      .refine(
        (contacts) =>
          contacts.some(
            (contact) => !contact.isStaffMember && (contact.sendEmail || contact.sendSms)
          ),
        {
          message: EMPTY_CONTACT_SELECTIONS_ERROR_MESSAGE,
        }
      ),
  })

  // Form object
  const form = useForm<OfferEditorData>({
    mode: 'uncontrolled',
    validateInputOnBlur: true,
    initialValues: {
      offerLotsInfo:
        offerData?.offerLots.map((lot) => ({
          isTested: isTestedLot(lot),
          greasyOfferPrice: null,
          cleanOfferPrice: null,
          greasyMarketPrice: null,
          cleanMarketPrice: null,
          valueAdd: null,
          displayValueAdd: true,
          brandPartner: null,
        })) || [],
      offerContactSelections: [],
      expiryDateTime: undefined,
      paymentDate: undefined,
      personalisedComment: undefined,
      from: account?.name ?? undefined,
    },
    onValuesChange: () => beginOfferPreview(),
    validate: zodResolver(formSchema),
  })

  // Only fires if the form is valid
  const onSubmit = () => {
    openSendModal()
  }

  // Offer preview state
  const [offerPreviewData, setOfferPreviewData] = useState<MutationCreateOfferArgsAllowUndefined>(
    {}
  )
  const [loadingOfferPreview, setLoadingOfferPreview] = useState<boolean>(false)

  const beginOfferPreview = () => {
    setLoadingOfferPreview(true)
    renderOfferPreview()
  }

  const renderOfferPreview = useDebouncedCallback(async () => {
    setOfferPreviewData(buildOfferData())
    setLoadingOfferPreview(false)
  }, OFFER_PREVIEW_DEBOUNCE_TIME)

  // Only fires if the form is invalid
  const onValidationFailure = () => () => {
    showErrorNotification('Please complete all required fields', { autoClose: true })
  }

  // Memoize the lot cards to prevent unnecessary re-renders
  const lotCards = useMemo(
    () =>
      offerData?.offerLots.map((lot, index) => (
        <OfferLotCard key={lot.internalReferenceCode} lot={lot} form={form} index={index} />
      )),
    [offerData?.offerLots, form]
  )

  const currentUserOfferContactOption: OfferContactOption = useMemo(() => {
    const splits: string[] = (account?.name ?? '').split(' ')

    return {
      firstName: splits[0],
      lastName: splits.length > 1 ? splits[1] : '',
      email: account?.username ?? undefined,
      mobilePhone: undefined,
      crmLink: undefined,
      roles: ['Offer creator'],
      sendEmail: true,
      sendSms: false,
      sendReminders: false,
      isStaffMember: true,
    }
  }, [account])

  const allOfferContactOptions: OfferContactOption[] = useMemo(
    () => [
      ...(notesAndContactsQuery?.notesAndContacts?.offerContactOptions ?? []),
      currentUserOfferContactOption,
    ],
    [notesAndContactsQuery?.notesAndContacts?.offerContactOptions]
  )

  useEffect(() => {
    form.setValues({
      offerContactSelections: allOfferContactOptions,
    })
    form.resetDirty()
  }, [allOfferContactOptions])

  const remindersError = useCallback(() => {
    for (let i = 0; i < allOfferContactOptions.length; i += 1) {
      if (form?.errors[`offerContactSelections.${i}`]) {
        return { shouldDisplayError: true, errorIndex: i }
      }
    }
    return { shouldDisplayError: false, errorIndex: -1 }
  }, [allOfferContactOptions, form?.errors])

  return (
    <PageContainer title={PAGE_TITLES.OFFERS}>
      <form
        onSubmit={form.onSubmit(() => onSubmit(), onValidationFailure())}
        data-testid='form-test'
      >
        <Stack data-testid='offers'>
          <Title>
            Make offer to {offerData?.stationBrand}{' '}
            <Text component='span' c='gray.5' inherit>
              {offerData?.stationRecordNumber}
            </Text>
          </Title>

          {stationError && (
            <IconAlert
              level={AlertLevel.ERROR}
              title='Error'
              message='Failed to fetch additional station data'
            />
          )}

          {lotCards}

          <CRMErrorAlerts crmError={crmError} />

          {/* Hide content if no lots loaded */}
          {offerData?.offerLots && (
            <>
              <Grid gutter='sm' mt='md'>
                <Grid.Col span={9}>
                  <Stack>
                    <Title order={3} size='h4'>
                      Communications
                    </Title>
                    <OfferRecipientsTable
                      data={allOfferContactOptions}
                      form={form}
                      noDataMessage='No recipients available'
                      isLoading={crmIsLoading}
                    />
                    {/* Display EMPTY_CONTACT_SELECTIONS_ERROR_MESSAGE if there is one in form errors */}
                    {form?.errors?.offerContactSelections && (
                      <Text size='xs' c='red'>
                        {form?.errors?.offerContactSelections}
                      </Text>
                    )}
                    {/* Display REMINDERS_ERROR_MESSAGE if there is one in form errors */}
                    {remindersError()?.shouldDisplayError && (
                      <Text size='xs' c='red'>
                        {form?.errors[`offerContactSelections.${remindersError()?.errorIndex}`]}
                      </Text>
                    )}
                    <div>
                      <Button
                        variant='outline'
                        color='gray'
                        size='xs'
                        onClick={() => refetchCrmData()}
                        loading={crmIsFetching}
                      >
                        Refresh contacts table
                      </Button>
                    </div>
                  </Stack>
                </Grid.Col>
                <Grid.Col span={3}>
                  <Stack>
                    <Title order={3} size='h4'>
                      Offer Notes
                    </Title>
                    <Skeleton visible={crmIsLoading}>
                      {notesAndContactsQuery?.notesAndContacts?.offerNotes ? (
                        <Text>{notesAndContactsQuery?.notesAndContacts?.offerNotes}</Text>
                      ) : (
                        <Text fs='italic'>No notes to display.</Text>
                      )}
                    </Skeleton>
                  </Stack>
                </Grid.Col>
              </Grid>
              <Textarea
                aria-label='Personalised comment/clip feedback'
                placeholder='Commercial Team comment'
                label='Personalised comment/clip feedback'
                minRows={5}
                autosize
                {...form?.getInputProps('personalisedComment')}
                key={form?.key('personalisedComment')}
              />
              <SimpleGrid cols={{ base: 1, sm: 3 }} data-testid='dates'>
                <TextInput
                  label='From'
                  placeholder='Who the offer is from'
                  {...form?.getInputProps('from')}
                  key={form?.key('from')}
                />
                <DateInput
                  label='Expiry date and time'
                  placeholder='Pick expiry date and time'
                  valueFormat={EXPECTED_DATETIME_FORMAT}
                  minDate={new Date()}
                  {...form?.getInputProps('expiryDateTime')}
                  key={form?.key('expiryDateTime')}
                />
                <DateInput
                  label='Payment date'
                  placeholder='Pick payment date'
                  valueFormat={EXPECTED_DATE_FORMAT}
                  minDate={new Date()}
                  {...form?.getInputProps('paymentDate')}
                  key={form?.key('paymentDate')}
                />
              </SimpleGrid>
              <Title order={2}>Offer Preview {loadingOfferPreview && <Loader size='sm' />}</Title>
              <OfferForm readOnly brand={offerData?.stationBrand} formData={offerPreviewData} />
              <Group justify='flex-end' mt='md' gap='xs'>
                <Button variant='outline' color='gray' onClick={openCancelModal}>
                  Cancel
                </Button>
                <Button type='submit' disabled={!!crmError || stationError}>
                  Send Offer
                </Button>
              </Group>
            </>
          )}
        </Stack>
      </form>

      <Modal
        data-testid='cancel-offer-modal'
        title={
          <Flex justify='flex-start' align='center'>
            <IconTrash size={28} color='var(--mantine-color-gray-4)' />
            <Text fw={500} size='lg' ml='sm'>
              Cancel offer
            </Text>
          </Flex>
        }
        opened={cancelModalState}
        onClose={closeCancelModal}
        centered
        closeButtonProps={{ 'aria-label': 'Close modal' }}
        returnFocus={false}
      >
        Are you sure you want to cancel this offer?
        <Group justify='flex-end' mt='md' gap='xs'>
          <Button variant='transparent' onClick={closeCancelModal}>
            No
          </Button>
          <Button color='red' onClick={() => confirmCancel()}>
            Yes, cancel this offer
          </Button>
        </Group>
      </Modal>

      <Modal
        data-testid='send-offer-modal'
        title={
          <Flex justify='flex-start' align='center'>
            <IconMailForward size={28} color='var(--mantine-color-gray-4)' />{' '}
            <Text fw={500} size='lg' ml='sm'>
              Send offer
            </Text>
          </Flex>
        }
        opened={sendModalState}
        onClose={closeSendModal}
        centered
        closeButtonProps={{ 'aria-label': 'Close modal' }}
        returnFocus={false}
      >
        Are you sure you want to send this offer?
        <Group justify='flex-end' mt='md' gap='xs'>
          <Button variant='transparent' onClick={closeSendModal}>
            No
          </Button>
          <Button onClick={() => confirmSend()}>Yes, send this offer</Button>
        </Group>
      </Modal>
    </PageContainer>
  )
}
