import { useMemo } from 'react'
import { MantineReactTable, MRT_ColumnDef, useMantineReactTable } from 'mantine-react-table'
import { NumberFormatter } from '@mantine/core'
import {
  hubTableDefaultAlertBannerProps,
  hubTableDefaultProps,
} from '@/components/shared/table/table'
import { NoDataMessage } from '@/components/shared/table/table-no-data-message'
import { formatDate } from '@shared/helpers/format-date/format-date'
import { ReceivalHistoryDetail } from '@loom-api-types'

interface ReceivalHistoryTableProps {
  data: ReceivalHistoryDetail[]
  sumOfTotalBalesReceived?: number
  noDataMessage?: string
  isLoading?: boolean
  isError?: boolean
}

export const ReceivalHistoryTable = ({
  data = [],
  sumOfTotalBalesReceived,
  noDataMessage = 'No receival history available for this station',
  isLoading,
  isError,
}: ReceivalHistoryTableProps) => {
  const columns = useMemo<MRT_ColumnDef<ReceivalHistoryDetail>[]>(
    () => [
      {
        id: 'dateReceived',
        header: 'Date Received',
        accessorFn: (row) => (row.dateReceived == null ? null : new Date(row.dateReceived)),
        Cell: ({ cell }) => formatDate(cell.getValue<Date>(), 'DD/MM/YYYY'),
        Footer: () => <>Totals</>,
      },
      {
        accessorKey: 'purchaseAccountCode',
        header: 'Purchase Account',
      },
      {
        accessorKey: 'storageCentreCode',
        header: 'Centre',
      },
      {
        accessorKey: 'totalBalesReceived',
        header: 'Bales',
        mantineTableHeadCellProps: {
          align: 'right',
        },
        mantineTableBodyCellProps: {
          align: 'right',
        },
        mantineTableFooterCellProps: {
          align: 'right',
        },
        Footer: () => (
          <NumberFormatter value={sumOfTotalBalesReceived} thousandSeparator decimalScale={2} />
        ),
      },
    ],
    [sumOfTotalBalesReceived]
  )

  const table = useMantineReactTable({
    ...hubTableDefaultProps,
    columns,
    data,
    state: {
      isLoading,
      showAlertBanner: isError,
    },
    mantineToolbarAlertBannerProps: hubTableDefaultAlertBannerProps(isError),
    renderEmptyRowsFallback: () => <NoDataMessage noDataMessage={noDataMessage} />,
    mantineTableProps: {
      ...hubTableDefaultProps.mantineTableProps,
      'aria-label': 'Receival History',
    },
  })

  return <MantineReactTable table={table} />
}
