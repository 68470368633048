import { useMemo, useRef, useState } from 'react'
import { IconPrinter } from '@tabler/icons-react'
import { useParams } from 'react-router-dom'
import { Button, Group, Stack, Title } from '@mantine/core'
import { SeasonDropdown } from '@/components/shared/season-dropdown/season-dropdown'
import { WoolContractsTable } from '@/components/stations/wool-data/wool-contracts-table/wool-contracts-table'
import { GetWoolContractsGql } from '@/graphql/queries/get-wool-contracts.graphql'
import { useAuthenticatedGraphQuery } from '@/hooks/use-authenticated-query'

export function WoolContracts() {
  // Get the station record number from the URL
  const { stationRecordNumber } = useParams()
  const [seasonStartDate, setSeasonStartDate] = useState<Date | undefined>(undefined)

  const seasonFilterValue: number | undefined = useMemo(
    () => seasonStartDate?.getFullYear(),
    [seasonStartDate]
  )

  // Get the wool contracts data
  const { data, isLoading, isError } = useAuthenticatedGraphQuery({
    queryKey: ['woolContractsData', stationRecordNumber, seasonFilterValue],
    gql: GetWoolContractsGql,
    queryParams: {
      stationRecordNumber,
      season: seasonFilterValue,
    },
    enabled: !!stationRecordNumber,
  })

  // Store and pass through the reference of the print button to the table component
  const printButtonRef = useRef<HTMLButtonElement>(null)

  return (
    <Stack>
      <Group>
        <Title order={2} data-testid='station-wool-data-wool-contracts' mr='auto'>
          Wool Contracts
        </Title>
        <SeasonDropdown
          seasonsBeforeCurrent={5}
          seasonsAfterCurrent={4}
          setSeasonStartDate={setSeasonStartDate}
          setSeasonEndDate={() => undefined} // Season end date is currently not used by this page
        />
        <Button
          ref={printButtonRef}
          leftSection={<IconPrinter />}
          disabled={!data?.listWoolContracts?.results}
        >
          Print contracts
        </Button>
      </Group>
      <WoolContractsTable
        data={data?.listWoolContracts?.results ?? []}
        isLoading={isLoading}
        isError={isError}
        sumOfBalesContracted={data?.listWoolContracts?.sumOfBalesContracted}
        printButtonRef={printButtonRef}
        season={seasonFilterValue?.toString() ?? ''}
      />
    </Stack>
  )
}
