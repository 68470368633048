import { useEffect, useState } from 'react'
import { Select } from '@mantine/core'
import { STOCK_AGE_LABELS } from '@shared/constants/stock-ages'
import { StockAge } from '@loom-api-types'

type StockAgeDropdownProps = {
  setStockAge: (value: StockAge | null) => void
  initialStockAge?: StockAge
}

/**
 * A dropdown component for selecting the stock age.
 *
 * @param {Object} props - The component props.
 * @param {function} props.setStockAge - Function to set the selected stock age.
 * @param {string | null} props.initialStockAge - The initial stock age value.
 *
 * @returns {JSX.Element} The rendered StockAgeDropdown component.
 */
export function StockAgeDropdown({ setStockAge, initialStockAge }: StockAgeDropdownProps) {
  const [stockAgeOption, setStockAgeOption] = useState<string | null>(
    initialStockAge || StockAge.All
  )

  // Update the stock age when the dropdown changes
  useEffect(() => {
    setStockAge(stockAgeOption as StockAge | null)
  }, [stockAgeOption])

  return (
    <Select
      data={generateStockAgeOptions()}
      value={stockAgeOption}
      onChange={setStockAgeOption}
      allowDeselect={false}
      name='stock-age-select'
      aria-label='Stock age select'
    />
  )
}

/**
 * Generates an array of stock age options from the STOCK_AGE_LABELS constant.
 * Each option is an object containing a `value` and a `label` property.
 *
 * @returns {Array<{ value: string, label: string }>} An array of stock age options.
 */
export function generateStockAgeOptions() {
  return Object.entries(STOCK_AGE_LABELS).map(([value, label]) => ({ value, label }))
}
