import { MRT_ColumnDef } from 'mantine-react-table'
import { NumberFormatter, Pill, Text } from '@mantine/core'
import { PillAlert } from '@/components/shared/pill-alert'
import { LoomWoolLotOfferStatus, UnsoldLot } from '@loom-api-types'
import { OfferStatusDisplay } from './offer-status-display'

export const unsoldLotsColumns: MRT_ColumnDef<UnsoldLot>[] = [
  {
    accessorKey: 'season',
    header: 'Season',
    enableColumnFilter: true,
    filterVariant: 'select',
  },
  {
    accessorKey: 'saleNumber',
    header: 'Sale',
    enableColumnFilter: true,
    filterVariant: 'select',
  },
  {
    id: 'porg',
    accessorFn: (row) =>
      row.stationRecordNumber ? `${row.stationBrand} ${row.stationRecordNumber}` : '',
    header: 'P Org',
    enableColumnFilter: true,
    filterVariant: 'select',
  },
  {
    accessorKey: 'internalReferenceCode',
    header: 'Reference',
    enableColumnFilter: true,
    Cell: ({ row }) => (
      <>
        <Text size='sm'>{row.original.internalReferenceCode}</Text>
        {row.original.interlotWarning && (
          <Pill bg='gray.2' fw={600} aria-label='Interlot Part'>
            IP
          </Pill>
        )}
      </>
    ),
  },
  {
    accessorKey: 'code',
    header: 'Lot No',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'totalBales',
    header: 'Bales',
    enableColumnFilter: false,
    mantineTableHeadCellProps: {
      align: 'right',
    },
    mantineTableBodyCellProps: {
      align: 'right',
    },
    mantineTableFooterCellProps: {
      align: 'right',
    },
  },
  {
    accessorKey: 'mainBaleHeaderPurchaseDescription',
    header: 'Description',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'typeCurrent',
    header: 'Type',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'internalComment',
    header: 'Comment',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'coreTestMicron',
    header: 'Mic',
    enableColumnFilter: false,
    Cell: ({ cell }) => (
      <NumberFormatter value={cell.getValue<number>()} thousandSeparator decimalScale={1} />
    ),
  },
  {
    accessorKey: 'coreTestVegetableMatterBase',
    header: 'VM',
    enableColumnFilter: false,
    Cell: ({ cell }) => (
      <NumberFormatter value={cell.getValue<number>()} thousandSeparator decimalScale={1} />
    ),
  },
  {
    accessorKey: 'coreTestYield',
    header: 'YLD',
    enableColumnFilter: false,
    Cell: ({ cell }) => (
      <NumberFormatter value={cell.getValue<number>()} thousandSeparator decimalScale={1} />
    ),
  },
  {
    accessorKey: 'coreTestColourYZ',
    header: 'Col',
    enableColumnFilter: false,
    Cell: ({ cell }) => (
      <NumberFormatter value={cell.getValue<number>()} thousandSeparator decimalScale={1} />
    ),
  },
  {
    accessorKey: 'coreTestStapleLengthGreasy',
    header: 'SL',
    enableColumnFilter: false,
    Cell: ({ cell }) => (
      <NumberFormatter value={cell.getValue<number>()} thousandSeparator decimalScale={0} />
    ),
  },
  {
    accessorKey: 'coreTestStapleStrength',
    header: 'SS',
    enableColumnFilter: false,
    Cell: ({ cell }) => (
      <NumberFormatter value={cell.getValue<number>()} thousandSeparator decimalScale={0} />
    ),
  },
  {
    accessorKey: 'purchaseAccountName',
    header: 'P Acc Name',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'qualityScheme2',
    header: 'Cert',
    enableColumnFilter: false,
    Cell: ({ row }) => (
      <>
        <Text size='sm' c={row.original.qualitySchemeWarning ? 'red.9' : ''}>
          {row.original.qualityScheme2}
        </Text>
        {row.original.qualitySchemeWarning && <PillAlert text='Cert' />}
      </>
    ),
  },
  {
    accessorKey: 'status',
    enableColumnFilter: false,
    header: 'Status',
    Cell: ({ cell }) => (
      <>
        <OfferStatusDisplay
          loomWoolLotOfferStatus={cell.getValue<LoomWoolLotOfferStatus | undefined>()}
        />
      </>
    ),
  },
]
