import { Breed } from '@loom-api-types'

export const BREED_LABELS = {
  [Breed.All]: 'All breeds',
  [Breed.Merino]: 'Merino',
  [Breed.MidMicron]: 'Mid Micron',
  [Breed.QuarterBred]: 'Quarter Bred',
  [Breed.Unclassified]: 'Unclassified',
  [Breed.Xbd]: 'Cross Bred',
}

/**
 * Converts a breed value to its corresponding string label.
 *
 * @param breed - The breed value to convert.
 * @returns The string label corresponding to the given breed value.
 */
export const breedValueToString = (breed: Breed) => BREED_LABELS[breed]
