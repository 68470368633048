import { useMemo, useRef } from 'react'
import { MantineReactTable, MRT_ColumnDef, useMantineReactTable } from 'mantine-react-table'
import { useReactToPrint } from 'react-to-print'
import { Grid, NumberFormatter } from '@mantine/core'

import {
  hubTableDefaultAlertBannerProps,
  hubTableDefaultProps,
} from '@/components/shared/table/table'
import { NoDataMessage } from '@/components/shared/table/table-no-data-message'
import { transposeDataToContractDetails } from '@/components/stations/wool-data/wool-contracts-table/contract-details-helpers'
import { ContractDetailsSubtable } from '@/components/stations/wool-data/wool-contracts-table/contract-details-subtable'
import { ContractedLotsSubtable } from '@/components/stations/wool-data/wool-contracts-table/contracted-lots-subtable'
import { formatDate } from '@shared/helpers/format-date/format-date'
import { showErrorNotification } from '@shared/helpers/notifications/notifications'
import { WoolContract } from '@loom-api-types'
import { WoolContractsPrint } from './wool-contracts-print'
import { PrintOnly } from '@shared/components/print-only/print-only'

interface WoolContractsTableProps {
  data: WoolContract[]
  sumOfBalesContracted?: number
  noDataMessage?: string
  isLoading?: boolean
  isError?: boolean
  printButtonRef?: React.RefObject<HTMLButtonElement>
  season?: string
}

export const WoolContractsTable = ({
  data = [],
  sumOfBalesContracted = 0,
  noDataMessage = 'No records to display',
  isLoading,
  isError,
  printButtonRef,
  season = '',
}: WoolContractsTableProps) => {
  const columns = useMemo<MRT_ColumnDef<WoolContract>[]>(
    () => [
      {
        accessorKey: 'contractName',
        header: 'Contract Name',
        Footer: () => <>Totals</>,
      },
      {
        accessorKey: 'purchaseAccountRecordNumber',
        header: 'Contract Reference',
      },
      {
        accessorKey: 'balesContracted',
        header: 'Bales Contracted',
        Footer: () => <NumberFormatter value={sumOfBalesContracted} thousandSeparator />,
        mantineTableHeadCellProps: {
          align: 'right',
        },
        mantineTableBodyCellProps: {
          align: 'right',
        },
        mantineTableFooterCellProps: {
          align: 'right',
        },
      },
      {
        accessorKey: 'targetMicron',
        header: 'Target Micron',
        Cell: ({ cell }) => <NumberFormatter value={cell.getValue<number>()} decimalScale={2} />,
        mantineTableHeadCellProps: {
          align: 'right',
        },
        mantineTableBodyCellProps: {
          align: 'right',
        },
      },
      {
        accessorKey: 'contractDeliveryDate',
        header: 'Delivery Date',
        Cell: ({ cell }) => formatDate(cell.getValue<Date>(), 'DD/MM/YYYY'),
      },
      {
        accessorKey: 'averagePriceCLN',
        header: 'Average Price (Clean C/KG)',
        Cell: ({ cell }) => (
          <NumberFormatter
            prefix='$'
            value={cell.getValue<number>()}
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
          />
        ),
        mantineTableHeadCellProps: {
          align: 'right',
        },
        mantineTableBodyCellProps: {
          align: 'right',
        },
      },
    ],
    [sumOfBalesContracted]
  )

  const table = useMantineReactTable({
    ...hubTableDefaultProps,
    columns,
    data,
    state: {
      isLoading,
      showAlertBanner: isError,
    },
    initialState: {
      ...hubTableDefaultProps.initialState,
      pagination: undefined,
    },
    mantineToolbarAlertBannerProps: hubTableDefaultAlertBannerProps(isError),
    renderEmptyRowsFallback: () => <NoDataMessage noDataMessage={noDataMessage} />,
    mantineTableProps: {
      ...hubTableDefaultProps.mantineTableProps,
      withColumnBorders: false,
      'aria-label': 'Wool Contracts',
    },
    renderDetailPanel: ({ row }) => {
      const additionalData = transposeDataToContractDetails(row.original)
      return (
        <Grid>
          <Grid.Col span={{ base: 12, lg: 3 }}>
            <ContractDetailsSubtable data={additionalData} />
          </Grid.Col>
          <Grid.Col span={{ base: 12, lg: 9 }}>
            <ContractedLotsSubtable
              data={row.original.contractedLots}
              totalBalesAccepted={row.original.totalBalesAccepted}
            />
          </Grid.Col>
        </Grid>
      )
    },
    enablePagination: false,
  })

  // Print table functionality
  // -------------------------
  // The following logic is used to print the wool-contracts-print component, triggered by clicking a button
  // provided to the table component.
  //
  // The print function is provided by the react-to-print library, which requires a reference to the element
  // to be printed. This is achieved by wrapping the table in a div and passing a reference to the div to the
  // print function.

  // Store the table reference to pass to the print function
  const printComponentRef = useRef<HTMLDivElement>(null)

  // Printing function as defined by https://github.com/MatthewHerbst/react-to-print
  const handlePrintButtonClick = useReactToPrint({
    contentRef: printComponentRef,
  })

  const printContracts = () => {
    try {
      handlePrintButtonClick()
    } catch (error) {
      showErrorNotification('Printing failed. Please try again.')
    }
  }

  // Attach the print function to the print button
  if (printButtonRef?.current) {
    printButtonRef.current.onclick = printContracts
  }

  return (
    <>
      <div data-testid='wool-contracts-table'>
        <MantineReactTable table={table} />
      </div>
      <PrintOnly ref={printComponentRef}>
        <WoolContractsPrint
          data={data}
          sumOfBalesContracted={sumOfBalesContracted}
          season={season}
        />
      </PrintOnly>
    </>
  )
}
