import { useEffect, useState } from 'react'
import { Select } from '@mantine/core'
import { getWoolSeasonDates } from '@shared/helpers/get-wool-season-dates/get-wool-season-dates'
import { getSeasonYear } from '@shared/helpers/get-season-year/get-season-year'

const labelForCurrentSeason = 'Latest'

type SeasonDropdownProps = {
  seasonsBeforeCurrent?: number
  seasonsAfterCurrent?: number
  setSeasonStartDate: (value: Date | undefined) => void
  setSeasonEndDate: (value: Date | undefined) => void
  initialSeason?: string
  enabled?: boolean
}

/**
 * A dropdown component for selecting a wool season.
 *
 * @param {number} seasonsBeforeCurrent - The number of seasons to include before the current season in the dropdown options. Defaults to 5.
 * @param {number} seasonsAfterCurrent - The number of seasons to include after the current season in the dropdown options. Defaults to 0.
 * @param {function} setSeasonStartDate - A callback function to set the start date of the selected season.
 * @param {function} setSeasonEndDate - A callback function to set the end date of the selected season.
 * @param {string} initialSeason - The initial season to select in the dropdown. Defaults to the current season.
 * @param {boolean} enabled - Whether the dropdown is enabled. Defaults to true.
 *
 * @returns {JSX.Element} The rendered SeasonDropdown component.
 */
export function SeasonDropdown({
  seasonsBeforeCurrent = 5,
  seasonsAfterCurrent = 0,
  setSeasonStartDate,
  setSeasonEndDate,
  initialSeason,
  enabled = true,
}: SeasonDropdownProps) {
  const seasonOptions = generateSeasonOptions(seasonsBeforeCurrent, seasonsAfterCurrent)
  const [season, setSeason] = useState<string | null>(initialSeason || getSeasonYear().toString())
  // We want to revert the season value after re-enabling the dropdown. So store the previous
  // value here.
  const [seasonPriorToDisabled, setSeasonPriorToDisabled] = useState<string | null>(null)

  // Update the season start and end dates when the season changes
  useEffect(() => {
    const { startDate, endDate } = getWoolSeasonDates(season as string)
    setSeasonStartDate(startDate)
    setSeasonEndDate(endDate)
  }, [season])

  // React to changes in enabled state
  useEffect(() => {
    if (enabled) {
      if (seasonPriorToDisabled) setSeason(seasonPriorToDisabled) // Set back to whatever it was (if we have a value)
    } else {
      setSeasonPriorToDisabled(season)
      setSeason(null) // We want to clear the season when the dropdown is disabled
    }
  }, [enabled])

  return (
    <Select
      data={seasonOptions}
      value={season}
      onChange={setSeason}
      allowDeselect={false}
      name='season-select'
      aria-label='Season select'
      disabled={!enabled}
    />
  )
}

/**
 * Generates an array of season options for a dropdown menu.
 *
 * Season options are sorted descendingly by year, with the current season labeled as "Latest".
 *
 * @param {number} seasonsBeforeCurrent - The number of seasons before the current season to include.
 * @param {number} seasonsAfterCurrent - The number of seasons after the current season to include.
 * @returns {Array<{ value: string, label: string }>} An array of objects representing the season options.
 */
export function generateSeasonOptions(seasonsBeforeCurrent: number, seasonsAfterCurrent: number) {
  const seasonYear = getSeasonYear()
  const startYear = seasonYear - seasonsBeforeCurrent
  const endYear = seasonYear + seasonsAfterCurrent
  const options = []

  for (let year = endYear; year >= startYear; year -= 1) {
    options.push({
      value: year.toString(),
      label: year === seasonYear ? labelForCurrentSeason : year.toString(),
    })
  }

  return options
}
