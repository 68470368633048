import { IconExternalLink } from '@tabler/icons-react'
import { useParams } from 'react-router-dom'
import { Button, Grid, Group, Skeleton, Stack, TextInput, Title } from '@mantine/core'
import { AlertLevel, IconAlert } from '@/components/shared/icon-alert'
import { GetStationDetailsGql } from '@/graphql/queries/get-station-details.graphql'
import { useAuthenticatedGraphQuery } from '@/hooks/use-authenticated-query'
import { useStationCRMDetails } from '@/hooks/use-station-crm-details'
import { CRMErrorAlerts } from '@/pages/offers/create-offer/crm-error-alerts'

export enum CRMErrors {
  NotFound = 'NotFound',
  Ambiguous = 'Ambiguous',
}

export function StationDetailsPage() {
  const { stationRecordNumber } = useParams()

  const { data, isError, isLoading } = useAuthenticatedGraphQuery({
    queryKey: ['stationDetails', stationRecordNumber],
    gql: GetStationDetailsGql,
    queryParams: { recordNumber: stationRecordNumber },
  })

  const {
    data: crmData,
    error: crmError,
    isLoading: crmIsLoading,
  } = useStationCRMDetails(stationRecordNumber, true)

  const displayManagerName = (crmCompany: any | null, managerKey: string) => {
    if (!crmCompany) return ''
    return [crmCompany[`${managerKey}FirstName`], crmCompany[`${managerKey}LastName`]].join(' ')
  }

  return (
    <Stack data-testid='station-details'>
      <Group justify='space-between'>
        <Title order={2}>Station Details</Title>
        {crmData && crmData.crmCompany && crmData.crmCompany.crmLink && (
          <Button
            variant='outline'
            leftSection={<IconExternalLink size={14} />}
            component='a'
            href={crmData?.crmCompany?.crmLink}
            target='_blank'
          >
            Station Info
          </Button>
        )}
      </Group>

      {isError && (
        <IconAlert level={AlertLevel.ERROR} title='Error' message='Failed to fetch station data' />
      )}
      <CRMErrorAlerts crmError={crmError} notFoundIsError={false} />
      {data?.stationDetails === null ? (
        <IconAlert
          level={AlertLevel.ERROR}
          title='Station details not found'
          message={`No station found with record number ${stationRecordNumber}`}
        />
      ) : (
        <Stack>
          <Grid>
            <Grid.Col span={6}>
              <Stack>
                <Skeleton visible={isLoading}>
                  <TextInput
                    label='Farm Group'
                    value={data?.stationDetails?.stationGroupDescription ?? ''}
                    readOnly
                    variant='filled'
                  />
                </Skeleton>
                <Skeleton visible={crmIsLoading}>
                  <TextInput
                    label='Trading Name'
                    value={crmData?.crmCompany?.tradingName ?? ''}
                    readOnly
                    variant='filled'
                  />
                </Skeleton>
              </Stack>
            </Grid.Col>
            <Grid.Col span={6}>
              <Stack>
                <Skeleton visible={crmIsLoading}>
                  <TextInput
                    label='NZM Area Manager'
                    value={displayManagerName(crmData?.crmCompany, 'nzmAreaManager')}
                    readOnly
                    variant='filled'
                  />
                </Skeleton>
                <Skeleton visible={crmIsLoading}>
                  <TextInput
                    label='Account Manager'
                    value={displayManagerName(crmData?.crmCompany, 'accountManagerName')}
                    readOnly
                    variant='filled'
                  />
                </Skeleton>
              </Stack>
            </Grid.Col>
          </Grid>

          <Stack gap='xs'>
            <Title order={3} size='h4'>
              Physical Address
            </Title>
            <Skeleton visible={crmIsLoading}>
              <Grid>
                <Grid.Col span={6}>
                  <Stack>
                    <TextInput
                      label='Street and Suburb'
                      value={crmData?.crmCompany?.physicalAddressStreetAndSuburb ?? ''}
                      readOnly
                      variant='filled'
                    />
                    <TextInput
                      label='City/Region/State'
                      value={crmData?.crmCompany?.physicalAddressCityRegionState ?? ''}
                      readOnly
                      variant='filled'
                    />
                    <TextInput
                      label='Rapid Number'
                      value={crmData?.crmCompany?.physicalAddressRapidNumber ?? ''}
                      readOnly
                      variant='filled'
                    />
                  </Stack>
                </Grid.Col>
                <Grid.Col span={6}>
                  <Stack>
                    <TextInput
                      label='Town'
                      value={crmData?.crmCompany?.physicalAddressTown ?? ''}
                      readOnly
                      variant='filled'
                    />
                    <TextInput
                      label='Country'
                      value={crmData?.crmCompany?.physicalAddressCountry ?? ''}
                      readOnly
                      variant='filled'
                    />
                  </Stack>
                </Grid.Col>
              </Grid>
            </Skeleton>
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}
