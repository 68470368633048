import { useMemo, useState } from 'react'
import { Button, Flex, Modal, Text } from '@mantine/core'
import { MantineReactTable, useMantineReactTable, type MRT_ColumnDef } from 'mantine-react-table'
import { PaginationState, ColumnFiltersState, OnChangeFn } from '@tanstack/react-table'
import {
  hubTableDefaultProps,
  hubTableDefaultAlertBannerProps,
} from '@/components/shared/table/table'
import {
  ListOffersResponse,
  LoomWoolLotOfferStatus,
  OfferDto,
  OfferStationOption,
  OfferStatus,
} from '@loom-api-types'
import { formatDate } from '@shared/helpers/format-date/format-date'
import {
  OfferStatusDisplay,
  OfferStatusOptionsLabels,
} from '@/components/offers/offer-status-display'
import { OfferLinesSubtable } from '@/components/offers/offers-table/offer-lines-subtable'
import { useDisclosure } from '@mantine/hooks'
import { IconMessage } from '@tabler/icons-react'

type OffersTableProps = {
  data: ListOffersResponse | undefined
  offerStationsData: Array<OfferStationOption> | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  pagination: PaginationState
  setPagination: OnChangeFn<PaginationState>
  columnFilters: ColumnFiltersState
  setColumnFilters: OnChangeFn<ColumnFiltersState>
}

export function OffersTable({
  data,
  offerStationsData,
  isError,
  isFetching,
  isLoading,
  pagination,
  setPagination,
  columnFilters,
  setColumnFilters,
}: OffersTableProps) {
  const [modalComment, setModalComment] = useState<string | undefined>(undefined)
  const [isCommentModalOpen, { open: openCommentModal, close: closeCommentModal }] =
    useDisclosure(false)

  const columns = useMemo<MRT_ColumnDef<OfferDto>[]>(
    () => [
      {
        accessorKey: 'stationName',
        header: 'Brand & P Org',
        enableColumnFilter: true,
        filterVariant: 'multi-select',
        accessorFn: (row) =>
          row.stationRecordNumber ? `${row.stationBrand} ${row.stationRecordNumber}` : '',
        mantineFilterMultiSelectProps: {
          data:
            offerStationsData?.map((station) => ({
              value: station.recordNumber,
              label: station.display,
            })) ?? [],
        },
      },
      {
        accessorKey: 'status',
        header: 'Status',
        enableColumnFilter: true,
        filterVariant: 'multi-select',
        Cell: ({ cell }) => (
          <OfferStatusDisplay
            loomWoolLotOfferStatus={cell.getValue<LoomWoolLotOfferStatus | undefined>()}
          />
        ),
        mantineFilterMultiSelectProps: {
          data: [
            { value: OfferStatus.Cancelled, label: OfferStatusOptionsLabels.Cancelled },
            { value: OfferStatus.Complete, label: OfferStatusOptionsLabels.Complete },
            { value: OfferStatus.Expired, label: OfferStatusOptionsLabels.Expired },
            { value: OfferStatus.UnderOffer, label: OfferStatusOptionsLabels.UnderOffer },
          ],
        },
      },
      {
        accessorKey: 'offerFrom',
        header: 'From',
        enableColumnFilter: false,
      },
      {
        accessorKey: 'created',
        header: 'Sent',
        enableColumnFilter: false,
        accessorFn: (row) => (row.created == null ? null : new Date(row.created)),
        Cell: ({ cell }) => formatDate(cell.getValue<Date>(), 'DD/MM/YYYY h:mm A'),
      },
      {
        accessorKey: 'completedDateTime',
        header: 'Completed',
        enableColumnFilter: false,
        accessorFn: (row) =>
          row.completedDateTime == null ? null : new Date(row.completedDateTime),
        Cell: ({ cell }) => formatDate(cell.getValue<Date>(), 'DD/MM/YYYY h:mm A'),
      },
      {
        accessorKey: 'paymentDate',
        header: 'Payment',
        enableColumnFilter: false,
        accessorFn: (row) => (row.paymentDate == null ? null : new Date(row.paymentDate)),
        Cell: ({ cell }) => formatDate(cell.getValue<Date>(), 'DD/MM/YYYY'),
      },
      {
        accessorKey: 'expiryDateTime',
        header: 'Expiry',
        enableColumnFilter: false,
        accessorFn: (row) => (row.expiryDateTime == null ? null : new Date(row.expiryDateTime)),
        Cell: ({ cell }) => formatDate(cell.getValue<Date>(), 'DD/MM/YYYY h:mm A'),
      },
      {
        accessorKey: 'createdBy',
        header: 'Creator',
        enableColumnFilter: false,
      },
      {
        accessorKey: 'personalisedComment',
        header: 'Comment',
        enableColumnFilter: false,
        Cell: ({ cell }) =>
          cell.getValue<string>() ? (
            <Button
              variant='outline'
              size='compact-sm'
              onClick={() => {
                openCommentModal()
                setModalComment(cell.getValue<string>())
              }}
            >
              Show comment
            </Button>
          ) : (
            <Text c='gray.4' size='sm'>
              No comment
            </Text>
          ),
      },
    ],
    [offerStationsData]
  )

  const table = useMantineReactTable({
    ...hubTableDefaultProps,
    columns,
    data: data?.results ?? [],
    initialState: {
      ...hubTableDefaultProps.initialState,
      showColumnFilters: true,
    },
    state: {
      isLoading,
      columnFilters,
      pagination,
      showProgressBars: isFetching,
      showAlertBanner: isError,
    },
    mantineToolbarAlertBannerProps: hubTableDefaultAlertBannerProps(isError),
    // Pagination
    manualPagination: true,
    rowCount: data?.totalResultCount,
    pageCount: data?.totalPageCount,
    onPaginationChange: setPagination,
    // Column filters
    enableFilters: true,
    manualFiltering: true,
    onColumnFiltersChange: setColumnFilters,
    // Subtable
    renderDetailPanel: ({ row }) => {
      return <OfferLinesSubtable data={row.original.lines} />
    },
  })

  return (
    <>
      <MantineReactTable table={table} />
      <Modal
        id='test-comment-modal'
        opened={isCommentModalOpen}
        onClose={closeCommentModal}
        centered
        title={
          <Flex justify='flex-start' align='center'>
            <IconMessage size={28} color='var(--mantine-color-gray-4)' />
            <Text fw={500} size='lg' ml='sm'>
              Offer Comment
            </Text>
          </Flex>
        }
        closeButtonProps={{ 'aria-label': 'Close modal' }}
        returnFocus={false}
      >
        {modalComment}
      </Modal>
    </>
  )
}
