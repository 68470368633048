import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Stack, Tabs, Title } from '@mantine/core'
import { PageContainer } from '@/components/shared/app-shell/page-container/page-container'
import { PAGE_TITLES } from '@/constants/page-titles'
import { UnsoldLots } from '@/pages/offers/unsold-lots/unsold-lots.page'
import { ViewOffers } from '@/pages/offers/view-offers/view-offers.page'

export function OffersPage() {
  const navigate = useNavigate()
  const { offersTabValue } = useParams()

  // If root offers route, redirect to unsold lots panel by default
  useEffect(() => {
    if (offersTabValue === undefined) {
      navigate(`/offers/unsold-lots`)
    }
  }, [offersTabValue])

  return (
    <PageContainer title={PAGE_TITLES.OFFERS}>
      <Stack data-testid='offers'>
        <Title>Offers</Title>
        <Tabs
          keepMounted={false}
          defaultValue='unsold-lots'
          value={offersTabValue}
          onChange={(value) => navigate(`/offers/${value}`)}
        >
          <Tabs.List mb='md'>
            <Tabs.Tab value='unsold-lots'>Unsold Lots</Tabs.Tab>
            <Tabs.Tab value='view-offers'>View Offers</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value='unsold-lots'>
            <UnsoldLots />
          </Tabs.Panel>
          <Tabs.Panel value='view-offers'>
            <ViewOffers />
          </Tabs.Panel>
        </Tabs>
      </Stack>
    </PageContainer>
  )
}
