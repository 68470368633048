import { useMemo } from 'react'
import { ColumnFiltersState, OnChangeFn } from '@tanstack/table-core'
import { MantineReactTable, MRT_ColumnDef, useMantineReactTable } from 'mantine-react-table'
import { Link, useParams } from 'react-router-dom'
import { Anchor, NumberFormatter, Text } from '@mantine/core'
import {
  hubTableDefaultAlertBannerProps,
  hubTableDefaultProps,
} from '@/components/shared/table/table'
import { NoDataMessage } from '@/components/shared/table/table-no-data-message'
import { formatDate } from '@shared/helpers/format-date/format-date'
import { sortLotNumbers } from '@shared/helpers/sort-lot-numbers/sort-lot-numbers'
import { LotDetail, WoolSoldStatus } from '@loom-api-types'

export const enum ResultOptions {
  Sold = 'Sold',
  Unsold = 'Unsold',
}

interface LotDetailsTableProps {
  data: LotDetail[]
  sumOfTotalBales?: number
  sumOfTotalNetKg?: number
  weightedAverageOfMicron?: number
  weightedAverageOfVegetableMatter?: number
  weightedAverageOfYield?: number
  weightedAverageOfColour?: number
  weightedAverageOfStapleLength?: number
  weightedAverageOfStapleStrength?: number
  weightedAverageOfPriceGreasy?: number
  weightedAverageOfPriceClean?: number
  weightedAverageOfCoefficientOfVariationLaserscan?: number
  weightedAverageOfStapleAverageStrength?: number
  weightedAverageOfPointOfBreakTip?: number
  weightedAverageOfPointOfBreakMid?: number
  weightedAverageOfPointOfBreakBase?: number
  sumOfTotalGrossProceeds?: number
  weightedAverageOfLaserscanMicron?: number
  weightedAverageOfOfdaMicron?: number
  weightedAverageOfAirflowMicron?: number
  noDataMessage?: string
  isLoading?: boolean
  isError?: boolean
  columnFilters?: ColumnFiltersState
  setColumnFilters?: OnChangeFn<ColumnFiltersState>
  enableResultFilter?: boolean
}

export const LotDetailsTable = ({
  data = [],
  sumOfTotalBales,
  sumOfTotalNetKg,
  weightedAverageOfMicron,
  weightedAverageOfVegetableMatter,
  weightedAverageOfYield,
  weightedAverageOfColour,
  weightedAverageOfStapleLength,
  weightedAverageOfStapleStrength,
  weightedAverageOfPriceGreasy,
  weightedAverageOfPriceClean,
  weightedAverageOfCoefficientOfVariationLaserscan,
  weightedAverageOfStapleAverageStrength,
  weightedAverageOfPointOfBreakTip,
  weightedAverageOfPointOfBreakMid,
  weightedAverageOfPointOfBreakBase,
  sumOfTotalGrossProceeds,
  weightedAverageOfLaserscanMicron,
  weightedAverageOfOfdaMicron,
  weightedAverageOfAirflowMicron,
  noDataMessage = 'No lot details available for this station',
  isLoading,
  isError,
  columnFilters,
  setColumnFilters,
  enableResultFilter = true,
}: LotDetailsTableProps) => {
  const { stationRecordNumber } = useParams()

  const columns = useMemo<MRT_ColumnDef<LotDetail>[]>(
    () => [
      {
        accessorKey: 'referenceNumber',
        header: 'Reference',
        enableHiding: false,
        enableSorting: true,
        sortingFn: sortLotNumbers,
        enableColumnFilter: false,
        Footer: () => <>Totals & AV</>,
      },
      {
        accessorKey: 'description',
        header: 'Description',
        enableHiding: false,
        enableSorting: true,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'bales',
        header: 'Bales',
        Cell: ({ cell, row }) => {
          const searchParams = new URLSearchParams()
          if (row.original.referenceNumber && row.original.season) {
            searchParams.append('referenceNumber', row.original.referenceNumber)
            searchParams.append('season', row.original.season)
            return (
              <Anchor
                component={Link}
                to={`/stations/${stationRecordNumber}/wool-data/bale-details?${searchParams.toString()}`}
                size='sm'
              >
                {cell.getValue<number>()}
              </Anchor>
            )
          }
          return cell.getValue<number>()
        },
        mantineTableHeadCellProps: {
          align: 'right',
        },
        mantineTableBodyCellProps: {
          align: 'right',
        },
        mantineTableFooterCellProps: {
          align: 'right',
        },
        enableHiding: false,
        enableSorting: true,
        enableColumnFilter: false,
        Footer: () => (
          <>
            <Text size='sm'>TOTAL</Text>
            <NumberFormatter value={sumOfTotalBales} thousandSeparator />
          </>
        ),
      },
      {
        accessorKey: 'netKgs',
        header: 'Net Kg',
        Cell: ({ cell }) => <NumberFormatter value={cell.getValue<number>()} thousandSeparator />,
        mantineTableHeadCellProps: {
          align: 'right',
        },
        mantineTableBodyCellProps: {
          align: 'right',
        },
        mantineTableFooterCellProps: {
          align: 'right',
        },
        enableHiding: false,
        enableSorting: true,
        enableColumnFilter: false,
        Footer: () => (
          <>
            <Text size='sm'>TOTAL</Text>
            <NumberFormatter value={sumOfTotalNetKg} thousandSeparator />
          </>
        ),
      },
      {
        accessorKey: 'lineNumber',
        header: 'Line No',
        enableSorting: true,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'saleAndSeason',
        header: 'Sale / Season',
        enableSorting: true,
        enableColumnFilter: true,
        filterVariant: 'select',
      },
      {
        accessorKey: 'purchaseAccountName',
        header: 'P Acc Name',
        enableSorting: false,
        enableColumnFilter: true,
        filterVariant: 'select',
      },
      {
        accessorKey: 'coreTestMicron',
        header: 'Mic',
        Cell: ({ cell }) => (
          <NumberFormatter value={cell.getValue<number>()} thousandSeparator decimalScale={1} />
        ),
        enableSorting: true,
        enableColumnFilter: false,
        Footer: () => (
          <>
            <Text size='sm'>AV</Text>
            <NumberFormatter
              value={weightedAverageOfMicron ?? ''}
              thousandSeparator
              decimalScale={1}
            />
          </>
        ),
      },
      {
        accessorKey: 'coreTestVegetableMatterBase',
        header: 'VM',
        Cell: ({ cell }) => (
          <NumberFormatter value={cell.getValue<number>()} thousandSeparator decimalScale={1} />
        ),
        enableSorting: true,
        enableColumnFilter: false,
        Footer: () => (
          <>
            <Text size='sm'>AV</Text>
            <NumberFormatter
              value={weightedAverageOfVegetableMatter ?? ''}
              thousandSeparator
              decimalScale={1}
            />
          </>
        ),
      },
      {
        accessorKey: 'coreTestYield',
        header: 'YLD',
        Cell: ({ cell }) => (
          <NumberFormatter value={cell.getValue<number>()} thousandSeparator decimalScale={1} />
        ),
        enableSorting: false,
        enableColumnFilter: false,
        Footer: () => (
          <>
            <Text size='sm'>AV</Text>
            <NumberFormatter
              value={weightedAverageOfYield ?? ''}
              thousandSeparator
              decimalScale={1}
            />
          </>
        ),
      },
      {
        accessorKey: 'coreTestColourYZ',
        header: 'Col',
        Cell: ({ cell }) => (
          <NumberFormatter value={cell.getValue<number>()} thousandSeparator decimalScale={1} />
        ),
        enableSorting: true,
        enableColumnFilter: false,
        Footer: () => (
          <>
            <Text size='sm'>AV</Text>
            <NumberFormatter
              value={weightedAverageOfColour ?? ''}
              thousandSeparator
              decimalScale={1}
            />
          </>
        ),
      },
      {
        accessorKey: 'coreTestStapleLengthGreasy',
        header: 'SL',
        Cell: ({ cell }) => (
          <NumberFormatter value={cell.getValue<number>()} thousandSeparator decimalScale={0} />
        ),
        enableSorting: true,
        enableColumnFilter: false,
        Footer: () => (
          <>
            <Text size='sm'>AV</Text>
            <NumberFormatter
              value={weightedAverageOfStapleLength ?? ''}
              thousandSeparator
              decimalScale={0}
            />
          </>
        ),
      },
      {
        accessorKey: 'coreTestStapleStrength',
        header: 'SS',
        Cell: ({ cell }) => (
          <NumberFormatter value={cell.getValue<number>()} thousandSeparator decimalScale={0} />
        ),
        enableSorting: true,
        enableColumnFilter: false,
        Footer: () => (
          <>
            <Text size='sm'>AV</Text>
            <NumberFormatter
              value={weightedAverageOfStapleStrength ?? ''}
              thousandSeparator
              decimalScale={0}
            />
          </>
        ),
      },
      {
        accessorKey: 'type',
        header: 'Type',
        enableSorting: true,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'comment',
        header: 'Comment',
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'result',
        header: 'Result',
        enableSorting: false,
        enableColumnFilter: enableResultFilter,
        filterVariant: 'select',
        mantineFilterSelectProps: {
          data: [ResultOptions.Sold, ResultOptions.Unsold],
        },
        Cell: ({ cell }) =>
          cell.getValue<string>() === WoolSoldStatus.SoldOrBin ? ResultOptions.Sold : '',
      },
      {
        accessorKey: 'priceGreasy',
        header: 'Price Greasy',
        Cell: ({ cell }) => (
          <NumberFormatter
            suffix='c'
            value={cell.getValue<number>()}
            thousandSeparator
            decimalScale={0}
          />
        ),
        mantineTableHeadCellProps: {
          align: 'right',
        },
        mantineTableBodyCellProps: {
          align: 'right',
        },
        mantineTableFooterCellProps: {
          align: 'right',
        },
        enableSorting: true,
        enableColumnFilter: false,
        Footer: () => (
          <>
            <Text size='sm'>AV</Text>
            <NumberFormatter
              value={weightedAverageOfPriceGreasy ?? ''}
              suffix='c'
              thousandSeparator
              decimalScale={0}
            />
          </>
        ),
      },
      {
        accessorKey: 'priceClean',
        header: 'Price Clean',
        Cell: ({ cell }) => (
          <NumberFormatter
            suffix='c'
            value={cell.getValue<number>()}
            thousandSeparator
            decimalScale={0}
          />
        ),
        mantineTableHeadCellProps: {
          align: 'right',
        },
        mantineTableBodyCellProps: {
          align: 'right',
        },
        mantineTableFooterCellProps: {
          align: 'right',
        },
        enableSorting: true,
        enableColumnFilter: false,
        Footer: () => (
          <>
            <Text size='sm'>AV</Text>
            <NumberFormatter
              value={weightedAverageOfPriceClean ?? ''}
              suffix='c'
              thousandSeparator
              decimalScale={0}
            />
          </>
        ),
      },
      {
        accessorKey: 'code',
        header: 'Lot No',
        enableSorting: true,
        enableColumnFilter: false,
        sortingFn: sortLotNumbers,
      },
      {
        accessorKey: 'cleanKgs',
        header: 'Clean Kg',
        Cell: ({ cell }) => (
          <NumberFormatter value={cell.getValue<number>()} thousandSeparator decimalScale={0} />
        ),
        mantineTableHeadCellProps: {
          align: 'right',
        },
        mantineTableBodyCellProps: {
          align: 'right',
        },
        mantineTableFooterCellProps: {
          align: 'right',
        },
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'coreTestCoefficientOfVariationLaserscan',
        header: 'LS CV',
        Cell: ({ cell }) => (
          <NumberFormatter value={cell.getValue<number>()} thousandSeparator decimalScale={1} />
        ),
        mantineTableHeadCellProps: {
          align: 'right',
        },
        mantineTableBodyCellProps: {
          align: 'right',
        },
        mantineTableFooterCellProps: {
          align: 'right',
        },
        enableSorting: true,
        enableColumnFilter: false,
        Footer: () => (
          <>
            <Text size='sm'>AV</Text>
            <NumberFormatter
              value={weightedAverageOfCoefficientOfVariationLaserscan ?? ''}
              thousandSeparator
              decimalScale={1}
            />
          </>
        ),
      },
      {
        accessorKey: 'coreTestStapleAverageStrength',
        header: 'SS25',
        Cell: ({ cell }) => <NumberFormatter value={cell.getValue<number>()} decimalScale={0} />,
        mantineTableHeadCellProps: {
          align: 'right',
        },
        mantineTableBodyCellProps: {
          align: 'right',
        },
        mantineTableFooterCellProps: {
          align: 'right',
        },
        enableSorting: false,
        enableColumnFilter: false,
        Footer: () => (
          <>
            <Text size='sm'>AV</Text>
            <NumberFormatter
              value={weightedAverageOfStapleAverageStrength ?? ''}
              thousandSeparator
              decimalScale={0}
            />
          </>
        ),
      },
      {
        accessorKey: 'coreTestPointOfBreakTip',
        header: 'POBt',
        Cell: ({ cell }) => <NumberFormatter value={cell.getValue<number>()} decimalScale={0} />,
        mantineTableHeadCellProps: {
          align: 'right',
        },
        mantineTableBodyCellProps: {
          align: 'right',
        },
        mantineTableFooterCellProps: {
          align: 'right',
        },
        enableSorting: false,
        enableColumnFilter: false,
        Footer: () => (
          <>
            <Text size='sm'>AV</Text>
            <NumberFormatter
              value={weightedAverageOfPointOfBreakTip ?? ''}
              thousandSeparator
              decimalScale={0}
            />
          </>
        ),
      },
      {
        accessorKey: 'coreTestPointOfBreakMid',
        header: 'POBm',
        Cell: ({ cell }) => <NumberFormatter value={cell.getValue<number>()} decimalScale={0} />,
        mantineTableHeadCellProps: {
          align: 'right',
        },
        mantineTableBodyCellProps: {
          align: 'right',
        },
        mantineTableFooterCellProps: {
          align: 'right',
        },
        enableSorting: false,
        enableColumnFilter: false,
        Footer: () => (
          <>
            <Text size='sm'>AV</Text>
            <NumberFormatter
              value={weightedAverageOfPointOfBreakMid ?? ''}
              thousandSeparator
              decimalScale={0}
            />
          </>
        ),
      },
      {
        accessorKey: 'coreTestPointOfBreakBase',
        header: 'POBb',
        Cell: ({ cell }) => <NumberFormatter value={cell.getValue<number>()} decimalScale={0} />,
        mantineTableHeadCellProps: {
          align: 'right',
        },
        mantineTableBodyCellProps: {
          align: 'right',
        },
        mantineTableFooterCellProps: {
          align: 'right',
        },
        enableSorting: false,
        enableColumnFilter: false,
        Footer: () => (
          <>
            <Text size='sm'>AV</Text>
            <NumberFormatter
              value={weightedAverageOfPointOfBreakBase ?? ''}
              thousandSeparator
              decimalScale={0}
            />
          </>
        ),
      },
      {
        accessorKey: 'grossProceeds',
        header: 'Gross Proceeds',
        Cell: ({ cell }) => (
          <NumberFormatter
            prefix='$'
            value={cell.getValue<number>()}
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
          />
        ),
        mantineTableHeadCellProps: {
          align: 'right',
        },
        mantineTableBodyCellProps: {
          align: 'right',
        },
        mantineTableFooterCellProps: {
          align: 'right',
        },
        enableSorting: true,
        enableColumnFilter: false,
        Footer: () => (
          <>
            <Text size='sm'>TOTAL</Text>
            <NumberFormatter
              prefix='$'
              value={sumOfTotalGrossProceeds}
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
            />
          </>
        ),
      },
      {
        id: 'paymentDate',
        header: 'Payment Date',
        accessorFn: (row) => (row.paymentDate == null ? null : new Date(row.paymentDate)),
        Cell: ({ cell }) => formatDate(cell.getValue<Date>(), 'DD/MM/YYYY'),
        enableSorting: true,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'coreTestMicronLaserscan',
        header: 'Laser',
        Cell: ({ cell }) => <NumberFormatter value={cell.getValue<number>()} decimalScale={1} />,
        mantineTableHeadCellProps: {
          align: 'right',
        },
        mantineTableBodyCellProps: {
          align: 'right',
        },
        mantineTableFooterCellProps: {
          align: 'right',
        },
        enableSorting: true,
        enableColumnFilter: false,
        Footer: () => (
          <>
            <Text size='sm'>AV</Text>
            <NumberFormatter
              value={weightedAverageOfLaserscanMicron ?? ''}
              thousandSeparator
              decimalScale={1}
            />
          </>
        ),
      },
      {
        accessorKey: 'coreTestMicronOfda',
        header: 'Ofda',
        Cell: ({ cell }) => <NumberFormatter value={cell.getValue<number>()} decimalScale={1} />,
        mantineTableHeadCellProps: {
          align: 'right',
        },
        mantineTableBodyCellProps: {
          align: 'right',
        },
        mantineTableFooterCellProps: {
          align: 'right',
        },
        enableSorting: true,
        enableColumnFilter: false,
        Footer: () => (
          <>
            <Text size='sm'>AV</Text>
            <NumberFormatter
              value={weightedAverageOfOfdaMicron ?? ''}
              thousandSeparator
              decimalScale={1}
            />
          </>
        ),
      },
      {
        accessorKey: 'coreTestMicronAirflow',
        header: 'Airflow',
        Cell: ({ cell }) => <NumberFormatter value={cell.getValue<number>()} decimalScale={1} />,
        mantineTableHeadCellProps: {
          align: 'right',
        },
        mantineTableBodyCellProps: {
          align: 'right',
        },
        mantineTableFooterCellProps: {
          align: 'right',
        },
        enableSorting: true,
        enableColumnFilter: false,
        Footer: () => (
          <>
            <Text size='sm'>AV</Text>
            <NumberFormatter
              value={weightedAverageOfAirflowMicron ?? ''}
              thousandSeparator
              decimalScale={1}
            />
          </>
        ),
      },
      {
        id: 'weighDate',
        header: 'Weight Date',
        accessorFn: (row) => (row.weighDate == null ? null : new Date(row.weighDate)),
        Cell: ({ cell }) => formatDate(cell.getValue<Date>(), 'DD/MM/YYYY'),
        enableSorting: true,
        enableColumnFilter: false,
      },
      {
        id: 'lottedDate',
        header: 'Lotted Date',
        accessorFn: (row) => (row.lottedDate == null ? null : new Date(row.lottedDate)),
        Cell: ({ cell }) => formatDate(cell.getValue<Date>(), 'DD/MM/YYYY'),
        enableSorting: true,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'qualityScheme2',
        header: 'ZQ/RX',
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'recordType',
        header: 'Record',
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'sample',
        header: 'Sample',
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'saleAccount',
        header: 'Sale Account',
        enableSorting: false,
        enableColumnFilter: false,
      },
    ],
    [
      enableResultFilter,
      sumOfTotalBales,
      sumOfTotalGrossProceeds,
      sumOfTotalNetKg,
      weightedAverageOfAirflowMicron,
      weightedAverageOfCoefficientOfVariationLaserscan,
      weightedAverageOfColour,
      weightedAverageOfLaserscanMicron,
      weightedAverageOfMicron,
      weightedAverageOfOfdaMicron,
      weightedAverageOfPointOfBreakBase,
      weightedAverageOfPointOfBreakMid,
      weightedAverageOfPointOfBreakTip,
      weightedAverageOfPriceClean,
      weightedAverageOfPriceGreasy,
      weightedAverageOfStapleAverageStrength,
      weightedAverageOfStapleLength,
      weightedAverageOfStapleStrength,
      weightedAverageOfVegetableMatter,
      weightedAverageOfYield,
    ]
  )

  const table = useMantineReactTable({
    ...hubTableDefaultProps,
    columns,
    data,
    initialState: {
      ...hubTableDefaultProps.initialState,
      columnVisibility: {
        code: false,
        cleanKgs: false,
        coreTestCoefficientOfVariationLaserscan: false,
        coreTestStapleAverageStrength: false,
        coreTestPointOfBreakTip: false,
        coreTestPointOfBreakMid: false,
        coreTestPointOfBreakBase: false,
        grossProceeds: false,
        paymentDate: false,
        coreTestMicronLaserscan: false,
        coreTestMicronOfda: false,
        coreTestMicronAirflow: false,
        weighDate: false,
        lottedDate: false,
        qualityScheme2: false,
        recordType: false,
        sample: false,
        saleAccount: false,
      },
      columnPinning: { left: ['referenceNumber'], right: [] },
      showColumnFilters: true,
    },
    state: {
      isLoading,
      showAlertBanner: isError,
      columnFilters,
    },
    mantineToolbarAlertBannerProps: hubTableDefaultAlertBannerProps(isError),
    renderEmptyRowsFallback: () => <NoDataMessage noDataMessage={noDataMessage} />,
    mantineTableProps: {
      ...hubTableDefaultProps.mantineTableProps,
      'aria-label': 'Lot Details',
    },
    // Sorting
    enableSorting: true,
    sortingFns: {
      sortLotNumbers,
    },
    // Ordering
    enableHiding: true,
    // Column filters
    enableFilters: true,
    manualFiltering: true,
    enableFacetedValues: true,
    onColumnFiltersChange: setColumnFilters,
    enableFilterMatchHighlighting: false,
    // Allow column reordering but not via header handles to save space
    enableColumnOrdering: true,
    enableColumnDragging: false,
    // Pin the first column
    enableColumnPinning: true,
  })

  return <MantineReactTable table={table} />
}
