import { NumberFormatter, Table } from '@mantine/core'
import { WoolLotOfferDto } from '@loom-api-types'
import { OfferStatusDisplay } from '@/components/offers/offer-status-display'

export type OfferSubtableData = Omit<WoolLotOfferDto, 'interlotWarning' | 'qualitySchemeWarning'>

type OfferLinesSubtableProps = {
  data: OfferSubtableData[]
  noDataMessage?: string
  isLoading?: boolean
  isError?: boolean
}

export const OfferLinesSubtable = ({ data = [] }: OfferLinesSubtableProps) => {
  const rows = data.map((offerLine) => (
    <Table.Tr key={offerLine.internalReferenceCode}>
      <Table.Td>
        <OfferStatusDisplay loomWoolLotOfferStatus={offerLine.status} />
      </Table.Td>
      <Table.Td>{offerLine.internalReferenceCode}</Table.Td>
      <Table.Td>{offerLine.lineNumber}</Table.Td>
      <Table.Td>{offerLine.mainBaleHeaderPurchaseDescription}</Table.Td>
      <Table.Td ta='right'>{offerLine.totalBales}</Table.Td>
      <Table.Td ta='right'>
        <NumberFormatter value={offerLine.coreTestMicron} thousandSeparator decimalScale={1} />
      </Table.Td>
      <Table.Td ta='right'>
        <NumberFormatter value={offerLine.coreTestVegetableMatterBase} decimalScale={1} />
      </Table.Td>
      <Table.Td ta='right'>
        <NumberFormatter value={offerLine.coreTestYield} thousandSeparator decimalScale={1} />
      </Table.Td>
      <Table.Td ta='right'>
        <NumberFormatter value={offerLine.coreTestColourYZ} thousandSeparator decimalScale={1} />
      </Table.Td>
      <Table.Td ta='right'>
        <NumberFormatter
          value={offerLine.coreTestStapleLengthGreasy}
          thousandSeparator
          decimalScale={0}
        />
      </Table.Td>
      <Table.Td ta='right'>
        <NumberFormatter
          value={offerLine.coreTestStapleStrength || undefined}
          thousandSeparator
          decimalScale={0}
        />
      </Table.Td>
      <Table.Td ta='right'>
        <NumberFormatter
          suffix='c'
          value={offerLine.cleanOfferPrice || undefined}
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
        />
      </Table.Td>
      <Table.Td ta='right'>
        <NumberFormatter
          suffix='c'
          value={offerLine.greasyOfferPrice || undefined}
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
        />
      </Table.Td>
      <Table.Td ta='right'>
        <NumberFormatter
          suffix='c'
          value={offerLine.cleanMarketPrice || undefined}
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
        />
      </Table.Td>
      <Table.Td ta='right'>
        <NumberFormatter
          prefix='$'
          value={offerLine.valueAdd}
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
        />
      </Table.Td>
      <Table.Td>{offerLine.brandPartner}</Table.Td>
    </Table.Tr>
  ))

  return (
    <Table withRowBorders={false} striped>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Status</Table.Th>
          <Table.Th>Ref</Table.Th>
          <Table.Th>Line</Table.Th>
          <Table.Th>Description</Table.Th>
          <Table.Th ta='right'>Bales</Table.Th>
          <Table.Th ta='right'>Mic</Table.Th>
          <Table.Th ta='right'>VM</Table.Th>
          <Table.Th ta='right'>YLD</Table.Th>
          <Table.Th ta='right'>Col</Table.Th>
          <Table.Th ta='right'>SL</Table.Th>
          <Table.Th ta='right'>SS</Table.Th>
          <Table.Th ta='right'>Offer Clean</Table.Th>
          <Table.Th ta='right'>Offer Greasy</Table.Th>
          <Table.Th ta='right'>Market at time (clean)</Table.Th>
          <Table.Th ta='right'>Value Add</Table.Th>
          <Table.Th>Brand Partner</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>{rows}</Table.Tbody>
    </Table>
  )
}
