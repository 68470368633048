import { NumberFormatter, Paper, Pill, Stack, Table, TableData, Text } from '@mantine/core'
import { WoolLotOfferModelInput } from '@loom-api-types'

type OfferFormLotCardProps = {
  lot: WoolLotOfferModelInput
  index: number
}

export function OfferFormLotCard({ lot, index }: OfferFormLotCardProps) {
  const tableData: TableData = {
    head: ['Desc', 'Bales', 'Mic', 'VM', 'Yld', 'Col', 'SL', 'SS', 'Line', 'Gsy Kg', 'Ref'],
    body: [
      [
        lot.mainBaleHeaderPurchaseDescription,
        lot.totalBales,
        <NumberFormatter value={lot.coreTestMicron} thousandSeparator decimalScale={1} />,
        <NumberFormatter
          value={lot.coreTestVegetableMatterBase}
          thousandSeparator
          decimalScale={1}
        />,
        <NumberFormatter value={lot.coreTestYield} thousandSeparator decimalScale={1} />,
        <NumberFormatter value={lot.coreTestColourYZ} thousandSeparator decimalScale={1} />,
        <NumberFormatter
          value={lot.coreTestStapleLengthGreasy}
          thousandSeparator
          decimalScale={0}
        />,
        <NumberFormatter value={lot.coreTestStapleStrength} thousandSeparator decimalScale={0} />,
        lot.lineNumber,
        <NumberFormatter value={lot.netKg} thousandSeparator />,
        <>
          <Text size='sm'>{lot.internalReferenceCode}</Text>
          {lot.interlotWarning && (
            <Pill bg='gray.2' fw={600} aria-label='Interlot Part'>
              IP
            </Pill>
          )}
        </>,
      ],
    ],
  }

  return (
    <Paper
      p='md'
      withBorder
      bd='1px solid gray.2'
      bg='gray.0'
      aria-label={`Offer Line ${index + 1}`}
    >
      <Stack>
        <Table withTableBorder withColumnBorders bg='white' data={tableData} />
        <Text size='lg'>
          Offer Price:{' '}
          <Text span fw={700}>
            {!!lot.cleanOfferPrice && (
              <NumberFormatter
                value={lot.cleanOfferPrice}
                suffix=' cln / '
                thousandSeparator
                decimalScale={0}
              />
            )}
            {!!lot.greasyOfferPrice && (
              <NumberFormatter
                value={lot.greasyOfferPrice}
                suffix=' gsy'
                thousandSeparator
                decimalScale={0}
              />
            )}
          </Text>
        </Text>
        <Text>
          <Text span>
            <Text span>The current market is approx </Text>
            {!!lot.cleanMarketPrice && (
              <NumberFormatter
                value={lot.cleanMarketPrice}
                suffix=' cln c/kg'
                thousandSeparator
                decimalScale={0}
              />
            )}
            {!!lot.cleanMarketPrice && !!lot.greasyMarketPrice && <Text span> and </Text>}
            {!!lot.greasyMarketPrice && (
              <NumberFormatter
                value={lot.greasyMarketPrice}
                suffix=' gsy c/kg'
                thousandSeparator
                decimalScale={0}
              />
            )}
            <Text span>.</Text>
          </Text>
          {lot.displayValueAdd && (
            <Text span>
              {' '}
              If accepted, the value added to this line compared to the current market will be
              approximately{' '}
              <NumberFormatter prefix='$' value={lot.valueAdd} thousandSeparator decimalScale={0} />
              .
            </Text>
          )}
          {lot.brandPartner && (
            <Text span>
              {' '}
              This line is destined for a{' '}
              <Text span fw={700}>
                {lot.brandPartner}
              </Text>{' '}
              order.
            </Text>
          )}
        </Text>
      </Stack>
    </Paper>
  )
}
