import { AlertLevel, IconAlert } from '@/components/shared/icon-alert'

type EnterOrScanAlertProps = {
  isError?: boolean
  message?: string
  title?: string
}

export function EnterOrScanAlert({
  isError = false,
  title = 'Enter or scan a reference number',
  message = 'Scan the sample barcode or select the Enter Reference button.',
}: EnterOrScanAlertProps) {
  const level = isError ? AlertLevel.WARNING : AlertLevel.INFO

  return <IconAlert title={title} message={message} level={level} />
}
