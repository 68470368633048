import { useContext, useMemo, useState } from 'react'
import { IconDownload } from '@tabler/icons-react'
import { useParams } from 'react-router-dom'
import { Button, Group, Stack, Switch, Title } from '@mantine/core'
import { SeasonDropdown } from '@/components/shared/season-dropdown/season-dropdown'
import { CreditorHistoryTable } from '@/components/stations/wool-data/creditor-history-table/creditor-history-table'
import { GetCreditorHistoryGql } from '@/graphql/queries/get-creditor-history.graphql'
import { toLocalDate } from '@shared/helpers/format-date/format-date'
import { generateCSV } from '@shared/helpers/generate-csv/generate-csv'
import { getSeasonYear } from '@shared/helpers/get-season-year/get-season-year'
import { useAuthenticatedGraphQuery } from '@/hooks/use-authenticated-query'
import { StationContext } from '@/pages/stations/station-context'

export function CreditorHistory() {
  const [seasonStartDate, setSeasonStartDate] = useState<Date | undefined>(undefined)
  const [seasonEndDate, setSeasonEndDate] = useState<Date | undefined>(undefined)
  const [includeStationGroup, setIncludeStationGroup] = useState(false)

  // Get the station record number from the URL
  const { stationRecordNumber } = useParams()

  // Get the station group data
  const stationContext = useContext(StationContext)

  // Convert Date objects to ISO date strings for GraphQL
  const { fromPaymentDate, toPaymentDate } = useMemo(
    () => ({
      fromPaymentDate: toLocalDate(seasonStartDate),
      toPaymentDate: toLocalDate(seasonEndDate),
    }),
    [seasonStartDate, seasonEndDate]
  )

  // Get the creditor history data
  const { data, isLoading, isError } = useAuthenticatedGraphQuery({
    queryKey: [
      'creditorHistoryData',
      stationRecordNumber,
      fromPaymentDate,
      toPaymentDate,
      includeStationGroup,
    ],
    gql: GetCreditorHistoryGql,
    queryParams: {
      stationRecordNumber,
      fromPaymentDate,
      toPaymentDate,
      includeStationGroup,
    },
    enabled: !!stationRecordNumber && !!fromPaymentDate && !!toPaymentDate,
  })

  const getCsvFilename = () =>
    `${stationRecordNumber}${includeStationGroup ? '-group' : ''}-creditor-history-${getSeasonYear(seasonStartDate)}.csv`

  const hasStationGroup = (stationContext?.stationsInGroup ?? []).length > 0

  return (
    <Stack>
      <Group>
        <Title order={2} data-testid='station-wool-data-creditor-history' mr='auto'>
          Creditor History
        </Title>
        {hasStationGroup && (
          <Switch
            label='Show data for entire farm group'
            checked={includeStationGroup}
            onChange={(event) => setIncludeStationGroup(event.currentTarget.checked)}
          />
        )}
        <SeasonDropdown
          seasonsBeforeCurrent={5}
          seasonsAfterCurrent={1}
          setSeasonStartDate={setSeasonStartDate}
          setSeasonEndDate={setSeasonEndDate}
        />
        <Button
          onClick={() => generateCSV(data?.listCreditorHistory?.results, getCsvFilename())}
          leftSection={<IconDownload />}
        >
          Download
        </Button>
      </Group>
      <CreditorHistoryTable
        data={data?.listCreditorHistory?.results ?? []}
        sumOfGrossProceedsExGST={data?.listCreditorHistory?.sumOfGrossProceedsExGST ?? null}
        sumOfNetProceedsGSTInclusive={
          data?.listCreditorHistory?.sumOfNetProceedsGSTInclusive ?? null
        }
        sumOfTotalBales={data?.listCreditorHistory?.sumOfTotalBales ?? null}
        sumOfTotalNetKg={data?.listCreditorHistory?.sumOfTotalNetKg ?? null}
        isLoading={isLoading}
        isError={isError}
      />
    </Stack>
  )
}
