import { FallbackProps } from 'react-error-boundary'
import { Button, Stack, Text, Title } from '@mantine/core'

export const MenuError = ({ error, resetErrorBoundary }: FallbackProps) => (
  <Stack p='md' ta='center' align='center'>
    <Title order={1} size='h4'>
      {error.name}
    </Title>
    <Text size='sm'>{error.message}</Text>
    <Button onClick={resetErrorBoundary}>Try again</Button>
  </Stack>
)
