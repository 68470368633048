import { gql } from 'graphql-request'

export const GetOfferStationsGql = gql`
  query getOfferStations {
    getOfferStations {
      brand
      recordNumber
      display
    }
  }
`
