import '@mantine/core/styles.css'
import '@mantine/dates/styles.css'
import '@mantine/notifications/styles.css'
import 'mantine-react-table/styles.css'
import './global.css'

import { StrictMode } from 'react'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ErrorBoundary } from 'react-error-boundary'
import { MantineProvider } from '@mantine/core'
import { Notifications } from '@mantine/notifications'
import { ErrorPage } from '@/pages/error.page'
import { Router } from './router'
import { theme } from './theme'

type AppProps = { msalInstance: PublicClientApplication }
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

export default function App({ msalInstance }: AppProps) {
  return (
    <StrictMode>
      <ErrorBoundary fallbackRender={ErrorPage}>
        <MantineProvider theme={theme}>
          <MsalProvider instance={msalInstance}>
            <QueryClientProvider client={queryClient}>
              <Router />
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </MsalProvider>
          <Notifications />
        </MantineProvider>
      </ErrorBoundary>
    </StrictMode>
  )
}
